import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class UserAgentService {
  isMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    return /android|iPad|iPhone|iPod/i.test(userAgent);
  }

  isDesktop(): boolean {
    return !this.isMobile();
  }
}
