import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "lib-relative-loader",
  templateUrl: "./relative-loader.component.html",
  styleUrls: ["./relative-loader.component.scss"],
  standalone: true,
  imports: [CommonModule]
})
export class RelativeLoaderComponent {
  @Input() withBackground = false;
}
