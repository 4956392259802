import { LoyaltyCardTypes } from "@app/pages/components/widget-settings/enroll-loyalty-widget-settings/enroll-loyalty-widget-settings";
import { ExternalLocation } from "../external-location";

export interface LoyaltyCard {
  id: string;
  code: string;
  url: string;
  status: string;
  name: string;
  title: string;
  description: string;
  type: string;
  loyaltyCardType: LoyaltyCardTypes;
  amountOfStamps: number;
  amountOfPoints: number;
  frontImage: string;
  logo: string;
  locations: ExternalLocation[];
}

export class IndustryLoyaltyCard {
  id: number;
  industryId: number;
  loyaltyType: LoyaltyCardTypes;
  loyaltyCard: LoyaltyCard;
  loyaltyCardId: string;
}

export enum LoyaltyExpirationPeriod {
  Never = "Never",
  Month = "Month"
}

export enum LoyaltyMembershipCardType {
  Discount = "Discount",
  General = "General",
  Tiered = "Tiered"
}

export enum LoyaltyMembershipTierType {
  Metal = "Metal",
  Diamond = "Diamond"
}

export interface LoyaltyProgramExpirationDto {
  period: LoyaltyExpirationPeriod;
  value: number;
}

export interface LoyaltyMembershipCardImagesRequestDto {
  discount?: Number;
  cardType: LoyaltyMembershipCardType;
  tierType?: LoyaltyMembershipTierType;
}

export interface LoyaltyMembershipCardGetDto {
  cardType: LoyaltyMembershipCardType;
  imageUrl: string;
  title: string;
}
