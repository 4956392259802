import { PlatformTypes } from "@app/models/platform-types";
import { NavMenuItem } from "./nav-menu";
import { ExternalFeatureCategories } from "@app/models/external-feature";
import { RoleTypes } from "core-kit";
import { Permission } from "@app/core";

// Admin:
const adminNavMenu: NavMenuItem[] = [
  {
    label: "Dashboard",
    identifier: Permission.Dashboard,
    icon: "ri-home-2-line",
    routePath: "/"
  },
  {
    label: "New registrations",
    icon: "ri-user-add-line",
    routePath: "/admin/newRegistrations"
  },
  {
    label: "ActivationCodes",
    icon: "ri-hashtag",
    routePath: "/activationCodes",
    pathWithParam: true
  },
  {
    label: "Partners",
    icon: "ri-user-star-line",
    routePath: "/partner",
    pathWithParam: true
  },
  {
    label: "Sales Agents",
    icon: "ri-user-2-line",
    routePath: "/admin/salesAgents"
  },
  {
    label: "Brands",
    icon: "ri-vip-crown-line",
    routePath: "/admin/brands"
  },
  {
    label: "accounts",
    icon: "ri-account-box-line",
    routePath: "/admin/accounts",
    pathWithParam: true
  },
  {
    label: "Locations",
    identifier: Permission.Locations,
    icon: "ri-store-2-line",
    routePath: "/admin/locations"
  },
  {
    label: "Landing Pages",
    icon: "ri-newspaper-line",
    routePath: "/admin/landings"
  },
  {
    label: "Users",
    identifier: Permission.Users,
    icon: "ri-user-line",
    routePath: "/admin/users"
  },
  {
    label: "Marketing Tools",
    identifier: Permission.MarketingTools,
    icon: "ri-settings-3-line",
    subItems: [
      {
        label: "GlobalTemplates",
        routePath: "/admin/templates"
      },

      {
        label: "Bulk Links",
        routePath: "/admin/bulk-links"
      }
    ]
  },
  {
    label: "Applications",
    icon: "ri-command-line",
    subItems: [
      {
        label: "User Applications",
        subItems: [
          {
            label: "2 REWARD ME",
            icon: "ri-edit-circle-line",
            function: "openPlatform",
            param: PlatformTypes.TwoReward
          },
          {
            label: "SMS USA",
            icon: "ri-edit-circle-line",
            function: "openPlatform",
            param: PlatformTypes.SmsUSA
          },
          {
            label: "SMS Global",
            icon: "ri-edit-circle-line",
            function: "openPlatform",
            param: PlatformTypes.SmsGlobal
          }
        ]
      },
      {
        label: "Admin Applications",
        subItems: [
          {
            label: "Manage Accounts",
            icon: "ri-edit-circle-line",
            hyperLink: "https://partners.vendasta.com/"
          },
          {
            label: "Manage Products",
            icon: "ri-edit-circle-line",
            hyperLink: "https://vendors.vendasta.com/products"
          },
          {
            label: "Sales & Success Center",
            icon: "ri-edit-circle-line",
            hyperLink: "https://clearline-mobile.snapshotreport.biz/"
          },
          {
            label: "Retention Center",
            icon: "ri-edit-circle-line",
            hyperLink: "https://clearline-mobile.smblogin.com/account/location"
          },
          {
            label: "Task Manager",
            icon: "ri-edit-circle-line",
            hyperLink: "https://task-manager.biz/partner/FOH9/my-work/tasks"
          },
          {
            label: "Billing Management",
            icon: "ri-edit-circle-line",
            hyperLink: "https://app.chargebee.com/login"
          },
          {
            label: "Kiosk App MDM",
            icon: "ri-edit-circle-line",
            hyperLink: "https://cloud.fully-kiosk.com/cloud/"
          }
        ]
      }
    ]
  },
  {
    label: "Widget management",
    icon: "ri-apps-line",
    subItems: [
      {
        label: "Campaign configuration",
        routePath: "/campaign-configuration"
      }
    ]
  },
  {
    label: "Import",
    routePath: "/admin/import",
    icon: "bx bx-import"
  },
  {
    label: "External features",
    icon: "ri-briefcase-4-line",
    routePath: "/admin/configured-external-features"
  }
];

// PartnerAdmin:
const partnerAdminNavMenu: NavMenuItem[] = [
  {
    label: "Dashboard",
    identifier: Permission.Dashboard,
    icon: "ri-home-2-line",
    routePath: "/"
  },
  {
    label: "New registrations",
    icon: "ri-user-add-line",
    routePath: "/admin/newRegistrations"
  },
  {
    label: "ActivationCodes",
    icon: "ri-hashtag",
    routePath: "/activationCodes",
    pathWithParam: true
  },
  {
    label: "Sales Agents",
    icon: "ri-user-2-line",
    routePath: "/admin/salesAgents"
  },
  {
    label: "Brands",
    icon: "ri-vip-crown-line",
    routePath: "/admin/brands"
  },
  {
    label: "accounts",
    icon: "ri-account-box-line",
    routePath: "/admin/accounts"
  },
  {
    label: "Locations",
    identifier: Permission.Locations,
    icon: "ri-store-2-line",
    routePath: "/admin/locations"
  },
  {
    label: "Users",
    identifier: Permission.Users,
    icon: "ri-user-line",
    routePath: "/admin/users"
  },
  {
    label: "Marketing Tools",
    identifier: Permission.MarketingTools,
    icon: "ri-settings-3-line",
    subItems: [
      {
        label: "GlobalTemplates",
        routePath: "/admin/templates"
      },
      {
        label: "Distributions",
        icon: "ri-hashtag",
        routePath: "/distributions",
        pathWithParam: true
      }
    ]
  },
  {
    label: "Partner Profile",
    icon: "ri-user-star-line",
    subItems: [
      {
        label: "Partner Details",
        routePath: "/partner/details"
      },
      {
        label: "Partner Admins",
        routePath: "/partner/users"
      },
      {
        label: "Delivery Settings",
        routePath: "/partner/delivery"
      }
    ]
  },
  {
    label: "Applications",
    icon: "ri-command-line",
    subItems: [
      {
        label: "User Applications",
        subItems: [
          {
            label: "2 REWARD ME",
            icon: "ri-edit-circle-line",
            function: "openPlatform",
            param: PlatformTypes.TwoReward
          },
          {
            label: "SMS USA",
            icon: "ri-edit-circle-line",
            function: "openPlatform",
            param: PlatformTypes.SmsUSA
          },
          {
            label: "SMS Global",
            icon: "ri-edit-circle-line",
            function: "openPlatform",
            param: PlatformTypes.SmsGlobal
          }
        ]
      }
    ]
  },
  {
    label: "Import",
    routePath: "/admin/import",
    icon: "bx bx-import"
  }
];

// Manager (Account Admin):
const managerNavMenu: NavMenuItem[] = [
  {
    label: "Dashboard",
    identifier: Permission.Dashboard,
    icon: "ri-home-2-line",
    routePath: "/"
  },
  {
    label: "Marketing Center",
    identifier: Permission.MarketingCenter,
    icon: "ri-megaphone-line",
    function: "openCmc",
    newTab: true,
    hidden: true
  },
  {
    label: "Marketing Tools",
    identifier: Permission.MarketingTools,
    icon: "ri-settings-3-line",
    hidden: true,
    subItems: [
      {
        label: "Marketing Apps Settings",
        identifier: Permission.MarketingAppsSettings,
        routePath: "/manager/app-settings"
      },
      {
        label: "Templates",
        identifier: Permission.Templates,
        routePath: "/manager/templates"
      },
      {
        label: "CFS Terminals",
        identifier: Permission.CFSTerminals,
        routePath: "manager/locations/cfsterminals",
        pathWithParam: true
      },
      {
        label: "Advanced Tools",
        routePath: "/manager/external-features/" + ExternalFeatureCategories[ExternalFeatureCategories.AdvancedMarketingTools],
        identifier: Permission.AdvancedTools
      },
      {
        label: "Outbound Marketing",
        routePath: "/manager/external-features/" + ExternalFeatureCategories[ExternalFeatureCategories.OutboundMarketing],
        identifier: Permission.OutboundMarketing
      },
      {
        label: "Distributions",
        identifier: Permission.Distributions,
        icon: "ri-hashtag",
        routePath: "/distributions",
        pathWithParam: true,

        hidden: true
      }
    ]
  },
  {
    label: "Digital Screens",
    identifier: Permission.DigitalScreens,
    icon: "ri-tv-2-line",
    hidden: true,
    subItems: [
      {
        label: "Digital Screens Dashboard",
        identifier: Permission.DigitalScreensDashboard,
        newTab: true,
        function: "openDigitalScreensDashboard",
        hidden: true
      }
    ]
  },
  {
    label: "URL’s & QR Codes",
    identifier: Permission.URLsAndQRCodes,
    icon: "ri-qr-code-line",
    hidden: true,
    subItems: [
      {
        label: "AppsShortURLs&QRs",
        identifier: Permission.AppsShortURLs,
        routePath: "manager/short-urls/apps"
      },
      {
        label: "CustomShortURLs&QRs",
        identifier: Permission.CustomShortURLs,
        routePath: "manager/short-urls/custom"
      },
      {
        label: "Pixel Tags",
        identifier: Permission.PixelTags,
        routePath: "/manager/pixel-tags"
      },
      {
        label: "Bulk Links",
        identifier: Permission.BulkLinks,
        routePath: "/manager/bulk-links"
      }
    ]
  },

  {
    label: "POS Integration",
    icon: "ri-book-3-line",
    identifier: Permission.POSIntegration,
    hidden: true,
    subItems: [
      {
        label: "POS Settings",
        routePath: "manager/pos-configuration",
        identifier: Permission.POSSettings,
        customDisplayHandling: true,
        hidden: true
      },
      {
        label: "POS Receipt Settings",
        routePath: "manager/pos-receipt-settings",
        identifier: Permission.POSReceiptSettings,
        customDisplayHandling: true,
        hidden: true
      },
      {
        label: "POS Rules",
        routePath: "manager/pos-rules",
        identifier: Permission.POSRules,
        customDisplayHandling: true,
        hidden: true
      },
      {
        label: "POS Activity Report",
        routePath: "manager/pos-activity-report",
        identifier: Permission.POSActivityReport,
        customDisplayHandling: true,
        hidden: true
      }
    ]
  },
  {
    label: "LoyaltyProgram",
    identifier: Permission.LoyaltyProgram,
    icon: "ri-service-line",
    hidden: true,
    subItems: [
      {
        label: "LoyaltyReporting",
        routePath: "manager/external-features/" + ExternalFeatureCategories[ExternalFeatureCategories.LoyaltyReporting],
        identifier: Permission.LoyaltyReporting,
        hidden: true
      }
    ]
  },
  {
    label: "ReportingAnalytics",
    identifier: Permission.ReportingAnalytics,
    icon: "ri-pie-chart-2-line",
    hidden: false,
    subItems: [
      {
        label: "ThirdPartyAnalytics",
        routePath: "manager/external-features/" + ExternalFeatureCategories[ExternalFeatureCategories.ReportingAnalytics],
        identifier: Permission.ThirdPartyAnalytics,
        customDisplayHandling: false,
        hidden: false
      },
      {
        label: "CustomTransactionReport",
        routePath: "manager/external-features/" + ExternalFeatureCategories[ExternalFeatureCategories.CustomTransactionReport],
        identifier: Permission.CustomTransactionReport,
        customDisplayHandling: true,
        hidden: true
      }
    ]
  },
  {
    label: "Contacts",
    identifier: Permission.Contacts,
    icon: "ri-contacts-line",
    routePath: "/manager/external-features/" + ExternalFeatureCategories[ExternalFeatureCategories.Contacts],
    hidden: true
  },
  {
    label: "Customers",
    identifier: Permission.Customers,
    icon: "ri-group-line",
    routePath: "/manager/customers",
    pathWithParam: true,
    hidden: true
  },
  {
    label: "Products",
    identifier: Permission.Products,
    icon: "ri-shopping-bag-line",
    routePath: "/manager/products/location/all",
    hidden: true
  },
  {
    label: "Company",
    identifier: Permission.CompanySettings,
    icon: "ri-building-line",
    hidden: true,
    subItems: [
      {
        label: "Locations",
        identifier: Permission.Locations,
        routePath: "/manager/locations"
      },
      {
        label: "Devices",
        identifier: Permission.Devices,
        routePath: "/manager/devices"
      },
      {
        label: "Users",
        identifier: Permission.Users,
        routePath: "/manager/users"
      },
      {
        label: "My Brand",
        identifier: Permission.MyBrand,
        routePath: "/manager/my-brand"
      },
      {
        label: "Connectors",
        function: "openExternalFeature",
        newTab: true,
        param: "connectors",
        identifier: Permission.CompanyConnectors,
        customDisplayHandling: true,
        hidden: true
      },
      {
        label: "Settings",
        identifier: Permission.ManagerSettings,
        routePath: "/manager/settings",
        hidden: true
      }
    ]
  }
];

// LocationManager:
const locationManagerNavMenu: NavMenuItem[] = [
  {
    label: "Dashboard",
    identifier: Permission.Dashboard,
    icon: "ri-home-2-line",
    routePath: "/"
  },
  {
    label: "Marketing Center",
    identifier: Permission.MarketingCenter,
    icon: "ri-megaphone-line",
    function: "openCmc",
    newTab: true,
    hidden: true
  },
  {
    label: "Marketing Tools",
    identifier: Permission.MarketingTools,
    icon: "ri-settings-3-line",
    hidden: true,
    subItems: [
      {
        label: "Marketing Apps Settings",
        identifier: Permission.MarketingAppsSettings,
        routePath: "/manager/app-settings"
      },
      {
        label: "Templates",
        identifier: Permission.Templates,
        routePath: "/manager/templates"
      },
      {
        label: "CFS Terminals",
        identifier: Permission.CFSTerminals,
        routePath: "manager/locations/cfsterminals",
        pathWithParam: true
      }
    ]
  },
  {
    label: "URL’s & QR Codes",
    identifier: Permission.URLsAndQRCodes,
    icon: "ri-qr-code-line",
    hidden: true,
    subItems: [
      {
        label: "AppsShortURLs&QRs",
        identifier: Permission.AppsShortURLs,
        routePath: "/manager/short-urls/apps"
      },
      {
        label: "CustomShortURLs&QRs",
        identifier: Permission.CustomShortURLs,
        routePath: "/manager/short-urls/custom"
      },
      {
        label: "Bulk Links",
        identifier: Permission.BulkLinks,
        routePath: "/manager/bulk-links"
      }
    ]
  },
  {
    label: "POS Integration",
    icon: "ri-book-3-line",
    hidden: true,
    subItems: [
      {
        label: "POS Rules",
        routePath: "manager/pos-rules",
        identifier: Permission.POSRules,
        customDisplayHandling: true,
        hidden: true
      }
    ]
  },
  {
    label: "LoyaltyProgram",
    identifier: Permission.LoyaltyProgram,
    icon: "ri-service-line",
    hidden: true,
    subItems: [
      {
        label: "LoyaltyReporting",
        routePath: "manager/external-features/" + ExternalFeatureCategories[ExternalFeatureCategories.LoyaltyReporting],
        identifier: Permission.LoyaltyReporting,
        hidden: true
      }
    ]
  },
  {
    label: "Customers",
    identifier: Permission.Customers,
    icon: "ri-group-line",
    routePath: "/manager/customers",
    pathWithParam: true,
    hidden: true
  },
  {
    label: "Company",
    identifier: Permission.CompanySettings,
    icon: "ri-building-line",
    hidden: true,
    subItems: [
      {
        label: "Locations",
        identifier: Permission.Locations,
        routePath: "/manager/locations"
      },
      {
        label: "Devices",
        identifier: Permission.Devices,
        routePath: "/manager/devices"
      },
      {
        label: "Users",
        identifier: Permission.Users,
        routePath: "/manager/users"
      },
      {
        label: "Settings",
        identifier: Permission.ManagerSettings,
        routePath: "/manager/settings",
        hidden: true
      }
    ]
  }
];

export const navMenuItemsByRole = {
  [RoleTypes.Admin]: adminNavMenu,
  [RoleTypes.PartnerAdmin]: partnerAdminNavMenu,
  [RoleTypes.Manager]: managerNavMenu,
  [RoleTypes.LocationManager]: locationManagerNavMenu
};
