import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AccountService, ProfileModel, UserPermissionService } from "@app/core";
import { AccountPosSystemSettingsConfiguration } from "@app/models";
import { PosSettingsService } from "@app/services";
import { Unsubscriber } from "@app/shared/components";
import { isIframe } from "clearline-common";
import { RoleTypes } from "core-kit";
import { filter } from "rxjs/operators";
import { EventService } from "../core/services/event.service";

export const LAYOUT_VERTICAL = "vertical";
export const LAYOUT_HORIZONTAL = "horizontal";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"]
  // todo: add OnPush ChangeDetectionStrategy
})
export class LayoutComponent extends Unsubscriber implements OnInit, AfterViewInit {
  private layoutType: string;

  isLoad = false;
  isMenuVisible = false;

  constructor(
    private eventService: EventService,
    private accountService: AccountService,
    private permissionService: UserPermissionService,
    private posSettingsService: PosSettingsService,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.sub = this.accountService.userChanged$.subscribe((user) => {
      this.isMenuVisible = this.visibleMenu(user);

      if (isIframe() && this.isMenuVisible) {
        this.layoutType = LAYOUT_HORIZONTAL;
      } else {
        this.layoutType = this.isMenuVisible ? LAYOUT_VERTICAL : LAYOUT_HORIZONTAL;
      }
    });

    this.sub = this.posSettingsService.settings$.pipe(filter((v) => !!v)).subscribe((settings: AccountPosSystemSettingsConfiguration) => {
      this.permissionService.updatePermissionsByPosSettingsUpdates(settings);
    });
  }

  visibleMenu(user: ProfileModel) {
    return !(user && !user.doneQuickSetup && user.role === RoleTypes.Manager);
  }

  ngOnInit() {
    this.sub = this.eventService.subscribe("changeLayout", (layout) => {
      this.layoutType = layout;
    });
  }

  ngAfterViewInit() {
    this.isLoad = true;

    this.cdr.detectChanges();
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }
}
