import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatStepper, MatStepperModule } from "@angular/material/stepper";
import { TranslocoModule } from "@ngneat/transloco";

export interface WizardStepItem {
  label: string;
  stepControl: AbstractControl;
  hidden?: boolean;
}

export interface WizardButtonOptions {
  label?: string;
  isWide?: boolean;
}

export enum WizardButton {
  cancel = "cancel",
  previous = "previous",
  next = "next",
  complete = "complete"
}

export interface WizardOptions {
  isModalWindow?: boolean;
  justifyContentCenter?: boolean;
  hiddenFooterButtons?: boolean;
  button?: {
    [key in WizardButton]?: WizardButtonOptions;
  };
}

const defaultWizardOptions: WizardOptions = {
  isModalWindow: false,
  hiddenFooterButtons: false
};

@Component({
  selector: "lib-wizard",
  templateUrl: "./wizard.component.html",
  styleUrls: ["./wizard.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslocoModule, MatStepperModule, FormsModule, ReactiveFormsModule],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class WizardComponent {
  @Input() public steps: WizardStepItem[] = [];
  @Input() public templates: TemplateRef<any>[] = [];
  @Input() public options: WizardOptions = defaultWizardOptions;

  @Output() public stepChange = new EventEmitter<number>();
  @Output() public confirm = new EventEmitter<void>();
  @Output() public cancel = new EventEmitter<void>();
  @Output() public stepperReady = new EventEmitter<WizardComponent>();

  @ViewChild("stepper", { static: true }) stepper!: MatStepper;

  static alwaysValidControl = new FormControl<boolean>(true, Validators.requiredTrue);

  get selectedIndex(): number {
    return this.stepper.selectedIndex;
  }

  get selectedLabel(): string {
    return this.stepper.selected?.label || "";
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.stepperReady.emit(this);
  }

  selectionChange(event: any): void {
    this.stepChange.emit(event.selectedIndex);
  }

  goToStep(step: number): void {
    this.stepper.selectedIndex = step;
  }

  updateView(): void {
    this.cdr.markForCheck();
  }
}
