import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy } from "@angular/core";
import Player, { Options, TimeEvent } from "@vimeo/player";
import { ExternalLinkVideoPlayerComponent } from "../external-link-video-player";
import { BehaviorSubject } from "rxjs";
import { provideClearlineCommonTranslocoScope } from "../../../utils";
import { TranslocoModule } from "@ngneat/transloco";
import { CfsTemplateMediaConfig } from "../../../models";
import { mediaReadyDelay } from "../../cfs-template";
import { RelativeLoaderComponent } from "../../relative-loader";

export const vimeoUrlPattern =
  /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]+\/)?videos\/|album\/(?:\d+\/)?video\/|)(\d+)(?:[?&]\S*)?$/;

@Component({
  selector: "lib-vimeo-player",
  templateUrl: "./vimeo-player.component.html",
  styleUrls: ["./vimeo-player.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslocoModule, RelativeLoaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideClearlineCommonTranslocoScope()]
})
export class VimeoPlayerComponent extends ExternalLinkVideoPlayerComponent implements OnDestroy {
  private isUrlValidationInProgress = false;

  protected player: Player | null = null;
  protected videoId = 0;
  protected isVideoValid$ = new BehaviorSubject<boolean>(true);
  protected urlPattern = vimeoUrlPattern;

  constructor(private element: ElementRef) {
    super();
  }

  play(): void {
    this.isVideoPlaying().then((isPlaying) => {
      if (!isPlaying) {
        this.player
          ?.play()
          .then(() => console.log("Vimeo video played")) // todo: remove logs after testing
          .catch((error) => {
            console.error("Error playing video: ", error); // todo: remove logs after testing
            this.setError();
            this.resetPlayer();
          });
      }
    });
  }

  pause(): void {
    this.player?.pause().then(() => console.log("Vimeo video paused")); // todo: remove logs after testing
  }

  stop(): void {
    this.player?.setCurrentTime(0).then(() => {
      console.log("Video reset to 0"); // todo: remove logs after testing
      this.pause();
    });
  }

  getVideoDuration(): Promise<number> {
    return this.player?.getDuration() || Promise.resolve(0);
  }

  getThumbnailUrl(): Promise<string> {
    return (
      this.player?.getVideoId().then((videoId) => {
        return videoId ? `https://vumbnail.com/${videoId}.jpg` : "";
      }) || Promise.resolve("")
    );
  }

  ngOnDestroy(): void {
    void this.resetPlayer();
  }

  protected loadPlayer(): void {
    if (!this.videoId) {
      this.setError();
      void this.resetPlayer();
      return;
    }

    if (this.urlChecked) {
      if (!this.player) {
        this.player = this.getPlayer();
      } else {
        this.player
          ?.loadVideo(this.videoId)
          .then(() => {})
          .catch((error) => {
            console.error("Video loading error: ", error);
            this.setError();
            void this.resetPlayer();
          });
      }
    } else {
      this.isVideoUrlValid()
        .then((isAccessible) => {
          this.urlChecked = true;

          if (isAccessible) {
            this.isVideoValid$.next(true);
            this.loadPlayer();
          } else {
            this.setError();
            void this.resetPlayer();
          }
        })
        .catch((error) => {
          console.error("Vimeo video error: ", error);
          this.urlChecked = true;
          this.setError();
          void this.resetPlayer();
        });
    }
  }

  protected getVideoIdByUrl(url: string): number {
    const match = url.match(this.urlPattern) || [];

    return match.length ? parseInt(match[1], 10) : 0;
  }

  private getPlayer(): Player {
    const options: Options = this.generatePlayerOptions(this.videoId, this.mediaConfig);
    const player: Player = new Player(this.element.nativeElement, options);

    player.on("ended", () => {
      if (!this.mediaConfig.loop) {
        this.stop();
      }
    });

    player.on("loaded", (event: TimeEvent) => {
      console.log("Vimeo video loaded: ", event); // todo: remove logs after testing
      setTimeout(() => {
        this.loaded = true;
        this.ready.emit();

        if (!!this.mediaConfig.autoplay) {
          this.play();
        }
      }, mediaReadyDelay);
    });

    player.on("error", (error) => {
      console.error("Vimeo player error: ", error);
      this.setError();

      if (error.name === "PlaybackError") {
        this.resetPlayer().then(() => this.loadPlayer());
      }
    });

    return player;
  }

  protected override setError() {
    super.setError();
    this.isVideoValid$.next(false);
  }

  private generatePlayerOptions(videoId: number, mediaConfig: CfsTemplateMediaConfig): Options {
    const { muted = true, loop = false } = mediaConfig || {};

    return {
      id: videoId,
      muted,
      byline: false,
      controls: false,
      loop,
      autoplay: false
    };
  }

  private async isVideoUrlValid(): Promise<boolean> {
    if (this.isUrlValidationInProgress) {
      return Promise.resolve(false);
    }

    this.isUrlValidationInProgress = true;

    try {
      // todo: temporary solution, move checking video url validity to back end:
      const response = await fetch("https://vimeo.com/api/oembed.json?url=" + encodeURIComponent(this.videoUrl));
      return response?.status === 200;
    } finally {
      this.isUrlValidationInProgress = false;
    }
  }

  private async resetPlayer(): Promise<any> {
    console.log("Vimeo player reset", !!this.player); // todo: remove logs after testing
    if (this.player) {
      await this.player.destroy();
      this.player = null;
    }

    return Promise.resolve();
  }

  private isVideoPlaying(): Promise<boolean> {
    if (!this.player) {
      return Promise.resolve(false);
    }

    return this.player.getPaused().then((paused) => !paused);
  }
}
