import { AllowedDeliveryChannels, FeatureTypeDto, SubscriptionAddonType, SubscriptionAppliance, SubscriptionPlanType } from "../enums";

export interface AccountSubscriptionDetailsDto {
  plan: SubscriptionPlanGetDto;
  addons: SubscriptionAddonGetDto[];
  externalFeatures: ExternalFeatureGetDto[];
  features: FeatureTypeDto[];
  widgets: DefaultWidgetGetDto[];
  featureSettings: FeatureSettingsDto;
}

export interface FeatureSettingsDto {
  terminal: TerminalFeatureSettingsDto;
}

export interface TerminalFeatureSettingsDto {
  limit: number;
}

export interface DefaultWidgetGetDto {
  id: number;
  name: string;
  description: string;
  notificationKey: string;
  packageId: number;
  isWidget: boolean;
  isCampaign: boolean;
  isAvailableForConditions: boolean;
  isAvailableForKiosk: boolean;
  useForUnified: boolean;
  useForClickableTemplate: boolean;
  useOnCfs: boolean;
  deliveryMethod: AllowedDeliveryChannels;
  videoId: string;
}

export interface ExternalFeatureGetDto {
  id: number;
  name: string;
  code: string;
  url: string;
  platformId: number;
  categoryId: number;
}

export interface SubscriptionAddonGetDto {
  id: number;
  name: string;
  description: string;
  defaultPriceConfiguration: PriceConfiguration;
  addonType: SubscriptionAddonType;
  info: string;
  imageUrl: string;
  limits: SubscriptionAddonLimits;
  features: FeatureTypeDto[];
  applianceType: SubscriptionAppliance;
}

export interface SubscriptionAddonLimits {
  dailyLimit: number;
  periodLimit: number;
}

export interface PriceConfiguration {
  price: number;
  productId: string;
  priceTable: Record<number, number>;
}

export interface SubscriptionPlanGetDto {
  id: number;
  name: string;
  description: string;
  limits: SubscriptionPlanLimits;
  options: SubscriptionPlanOptionsDto;
  price: number;
  periodInDays: number;
  productId: string;
  features: any;
  planType: SubscriptionPlanType;
  widgets: DefaultWidgetGetDto[];
  externalFeatures: ExternalFeatureGetDto[];
}

export interface SubscriptionPlanLimits {
  dailyLimit: number;
  dailyWarningCap: number;
  trialDailyLimit: number;
  trialDailyWarningCap: number;
  periodLimit: number;
  periodOverCapacity: number;
  periodWarningCap: number;
  trialPeriodLimit: number;
  trialPeriodOverCapacity: number;
  trialPeriodWarningCap: number;
  couponLimit?: number;
  reviewRequestLimit?: number;
  customShortLinkLimit: number;
  bulkSize: number;
  socialLinkLimit: number;
  isUnlimited: boolean;
}

export interface SubscriptionPlanOptionsDto {
  features: FeatureTypeDto[];
  showOnPlanChooser: boolean;
  hasDashboard: boolean;
  hasCmc: boolean;
  hasCfs: boolean;
  hasTrackingPixel: boolean;
  hasOutboundMarketing: boolean;
  hasReportingAndAnalytics: boolean;
  hasProducts: boolean;
  hasExternalContacts: boolean;
  hasPosIntegration: boolean;
  hasConnectors: boolean;
  hasCfsPrint: boolean;
  hasDistribution: boolean;
  hasCustomers: boolean;
  canViewBulks: boolean;
  canCreateBulks: boolean;
  canDeleteBulks: boolean;
  hasAdvancedTools: boolean;
  hasUrlsAndQrCodes: boolean;
  hasAppsShortUrlsAndQrs: boolean;
  hasCustomShortUrlsAndQrs: boolean;
  hasBulkLinksAndQrsList: boolean;
  hasLoyaltyProgram: boolean;
  hasLoyaltyProgramReporting: boolean;
  hasThirdPartyAnalytics: boolean;
  hasDevices: boolean;
  hasPrintableQrCodeFlyers: boolean;
  hasPrintReceipt: boolean;
  hasMarketingCampaigns: boolean;
  useAvailableForMenu: boolean;
  enabledForPartnerPayment: boolean;
  enabledForNewRegistration: boolean;
}

export interface AccountSubscriptionPostDto {
  subscriptionPlanId: number;
  locationQuantity: number;
  addons?: AccountSubscriptionAddonPostDto[];
}

export interface AccountSubscriptionAddonPostDto {
  subscriptionAddonId: number;
  quantity: number;
}

export interface SubscriptionGetDto {
  subscriptionPlanId: number;
  isTrial: boolean;
  startDate: Date; // string ISO?
  endDate: Date; // string ISO?
  dailyLimit: number;
  dailyWarningCap: number;
  periodLimit: number;
  periodOverCapacity: number;
  periodWarningCap: number;
  dailyCount: number;
  count: number;
}

export interface AccountSubscriptionGetDto {
  AccountId: number;
  SubscriptionPlanId: number;
  LocationQuantity: number;
  Addons: AccountSubscriptionAddonGetDto[];
}

export interface AccountSubscriptionAddonGetDto {
  SubscriptionAddonId: number;
  Quantity: number;
  ApplianceType: SubscriptionAppliance;
  Limits: SubscriptionAddonLimits;
}
