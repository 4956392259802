<div class="default-widget-modal" *transloco="let tr; read: 'widgets.enrollLoyalty'">
  <div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <app-loyalty-wizard
      [industryId]="industryId"
      [enrollLoyaltyLink]="enrollLoyaltyLink"
      [industryId]="industryId"
      (modalClose)="dismiss()"
      (resultChange)="saveResult($event)"
    ></app-loyalty-wizard>
  </div>

  <div class="modal-footer"></div>
</div>
