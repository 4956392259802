<!-- Transparent overlay to intercept inner iframe clicks -->
<div class="overlay"></div>

<ng-container *transloco="let t; read: i18nScope">
  <div *ngIf="displayUploadPlaceholder && (!videoId || !loaded && (isVideoValid$ | async))" class="upload-placeholder">
    <span *ngIf="!videoId">{{  t('media.video.uploadPlaceholder') }}</span>

    <lib-relative-loader *ngIf="videoId"></lib-relative-loader>
  </div>

  <div *ngIf="videoId && !(isVideoValid$ | async) && !player" class="empty-player">
    {{ t('media.video.notAccessible') }}
  </div>
</ng-container>
