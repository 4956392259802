<div class="default-widget-modal">
  <div class="modal-header">
    <h4 class="page-title-box m-0 p-0 font-bold">{{ title }}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div class="modal-body" *ngIf="loyaltyProgramDetailsResult">
    <app-loyalty-program-details
      *ngIf="!isMembershipCard && selectedCard"
      [rewardsList]="rewardsList"
      [enrollLoyaltyLink]="enrollLoyaltyLink"
      [selectedCard]="selectedCard"
      [loyaltyProgramDetailsResult]="loyaltyProgramDetailsResult"
      [selectedCardTypeSetting]="selectedCardTypeSetting"
    ></app-loyalty-program-details>

    <app-membership-program-details
      *ngIf="isMembershipCard"
      [programRequest]="loyaltyProgramDetailsResult"
    ></app-membership-program-details>
  </div>

  <div class="modal-footer" *transloco="let tcc; read: 'common.controls'"></div>
</div>
