import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, forwardRef } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";
import { Unsubscriber } from "@app/shared/components";
import { positiveIntegerValidator } from "@app/shared/validators";
import { nonFalsyPayload } from "clearline-api";
import { LoyaltyConditionConfiguration, LoyaltyMinimumRequirementMode } from "../../../enroll-loyalty-widget-settings";

@Component({
  selector: "app-min-requirements",
  templateUrl: "./min-requirements.component.html",
  styleUrls: ["./min-requirements.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MinRequirementsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinRequirementsComponent),
      multi: true
    }
  ]
})
export class MinRequirementsComponent extends Unsubscriber implements OnInit, ControlValueAccessor, AfterViewInit, Validator {
  @Input() isRequired = true;

  readonly baseInputValidators = [Validators.required, Validators.min(1)];
  readonly minReqRadioButtons = [
    { label: "widgets.enrollLoyalty.minAmount", value: LoyaltyMinimumRequirementMode.Amount, disabled: false },
    { label: "widgets.enrollLoyalty.minCount", value: LoyaltyMinimumRequirementMode.Count, disabled: false }
  ];

  form = new FormGroup({
    minimumRequirementMode: new FormControl<LoyaltyMinimumRequirementMode>(null, [Validators.required]),
    minimumAmount: new FormControl<number>({ value: null, disabled: true }, [...this.baseInputValidators]),
    minimumCount: new FormControl<number>({ value: null, disabled: true }, [...this.baseInputValidators])
  });

  private onChange: (value: LoyaltyConditionConfiguration) => void;
  private onTouched: () => void;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (!this.isRequired) {
      this.form.controls.minimumCount.setValidators([]);
      this.form.controls.minimumAmount.setValidators([]);
    }

    this.sub = this.form.controls.minimumRequirementMode.valueChanges.subscribe((value: LoyaltyMinimumRequirementMode) => {
      switch (value) {
        case LoyaltyMinimumRequirementMode.Count: {
          if (this.isRequired) {
            this.form.controls.minimumCount.setValidators([...this.baseInputValidators, positiveIntegerValidator]);
          }
          this.form.controls.minimumCount.enable({ emitEvent: false });
          this.form.controls.minimumAmount.setValue(null, { emitEvent: false });
          this.form.controls.minimumAmount.clearValidators();
          this.form.controls.minimumAmount.disable();
          break;
        }
        case LoyaltyMinimumRequirementMode.Amount: {
          if (this.isRequired) {
            this.form.controls.minimumAmount.setValidators([...this.baseInputValidators]);
          }
          this.form.controls.minimumAmount.enable({ emitEvent: false });
          this.form.controls.minimumCount.setValue(null, { emitEvent: false });
          this.form.controls.minimumCount.clearValidators();
          this.form.controls.minimumCount.disable();
          break;
        }
      }
    });

    this.sub = this.form.valueChanges.subscribe(() => {
      this.onModelChange();
    });
  }

  ngAfterViewInit(): void {
    const modeValue: LoyaltyMinimumRequirementMode =
      this.form.controls.minimumRequirementMode.value || LoyaltyMinimumRequirementMode.Amount;

    this.form.controls.minimumRequirementMode.setValue(modeValue, { emitEvent: true });
  }

  writeValue(value: LoyaltyConditionConfiguration): void {
    if (value) {
      const minimumRequirementMode = !!value.minimumCount ? LoyaltyMinimumRequirementMode.Count : LoyaltyMinimumRequirementMode.Amount;

      this.form.patchValue(
        {
          ...value,
          minimumRequirementMode
        },
        { emitEvent: false }
      );
    }
  }

  registerOnChange(fn: (value: LoyaltyConditionConfiguration) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let errors = null;

    if (this.form.invalid) {
      const controlNames = Object.keys(this.form.controls);
      errors = controlNames.reduce((acc, controlName) => {
        const control = this.form.controls[controlName];
        if (control.invalid) {
          acc[controlName] = control.errors;
        }
        return acc;
      }, {});
    }

    return errors;
  }

  private onModelChange(): void {
    if (this.onChange) {
      const { minimumRequirementMode, ...value } = nonFalsyPayload(this.form.getRawValue());

      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
