<div>
  <lib-wizard
    #wizard
    [steps]="steps"
    [templates]="[step1 ,step2, step3, step4]"
    [options]="wizardOptions"
    (stepChange)="onStepChange($event)"
    (cancel)="modalClose.emit()"
    (confirm)="saveResult()"
  ></lib-wizard>


  <ng-template #step1>
    <app-loyalty-program-type
      [industryList]="industryList$ | async"
      (selectCardType)="resetProgram()"
      (selectCard)="onSelectChanged($event)"
    ></app-loyalty-program-type>
  </ng-template>

  <ng-template #step2>
    <ng-container *ngIf="wizard.selectedIndex === 1 && !!selectedProgram">
      <app-loyalty-program-settings
        *ngIf="!isMembershipCard"
        [formData]="rewardProgramFormData"
        [selectedCardTypeSetting]="selectedCardTypeSetting"
        [expirationPeriodTitle]="expirationPeriodTitle"
        [expirationPeriodOptions]="expirationPeriodOptions$ | async"
        (reset)="onResetProgramSettings()"
        (stepUpdate)="updateLoyaltyProgramFormData($event)"
        (stepComplete)="updateProgramDetailsResult($event)"
        (valid)="updateStepValidity(1, $event)"
      ></app-loyalty-program-settings>

      <app-membership-program-settings
        *ngIf="isMembershipCard"
        [formData]="rewardProgramFormData"
        [selectedCardTypeSetting]="selectedCardTypeSetting"
        [membershipCardType]="selectedProgram.loyaltyCard.type"
        (stepUpdate)="updateLoyaltyProgramFormData($event)"
        (stepComplete)="updateLoyaltyProgramFormData($event)"
        (valid)="updateStepValidity(1, $event)"
      ></app-membership-program-settings>
    </ng-container>
  </ng-template>

  <ng-template #step3>
    <ng-container *ngIf="wizard.selectedIndex === 2">
      <app-reward-settings
        *ngIf="!isMembershipCard && loyaltyProgramDetailsResult.goalBalance"
        [goalBalance]="loyaltyProgramDetailsResult.goalBalance"
        [locationId]="locationId"
        [industryId]="industryId"
        [selectedCardTypeSetting]="selectedCardTypeSetting"
        [selectedCard]="selectedProgram?.loyaltyCard"
        [rewardsList]="rewardsList"
        [loyaltyProgramDetailsResult]="loyaltyProgramDetailsResult"
        (rewardsUpdate)="onRewardsUpdate($event)"
      ></app-reward-settings>

      <app-membership-card-settings
        *ngIf="isMembershipCard"
        [formData]="rewardProgramFormData"
        [selectedCardTypeSetting]="selectedCardTypeSetting"
        [membershipCardType]="selectedProgram.loyaltyCard.type"
        (stepUpdate)="updateLoyaltyProgramFormData($event)"
        (valid)="updateStepValidity(2, $event)"
      ></app-membership-card-settings>
    </ng-container>
  </ng-template>

  <ng-template #step4>
    <div class="row wizard-content-modal mt-4" *ngIf="wizard.selectedIndex === 3">
      <app-loyalty-program-details
        *ngIf="!isMembershipCard && loyaltyProgramDetailsResult"
        [enrollLoyaltyLink]="enrollLoyaltyLink"
        [rewardsList]="rewardsList"
        [selectedCard]="selectedProgram?.loyaltyCard"
        [loyaltyProgramDetailsResult]="loyaltyProgramDetailsResult"
        [selectedCardTypeSetting]="selectedCardTypeSetting"
        [expirationPeriodLabel]="expirationPeriodTitle"
      ></app-loyalty-program-details>

      <app-membership-program-details
        *ngIf="isMembershipCard"
        [programRequest]="loyaltyProgramDetailsResult"
      ></app-membership-program-details>
    </div>
  </ng-template>
</div>
