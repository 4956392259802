import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LoyaltyMembershipCardType, LoyaltyMembershipTierType } from "clearline-api";
import { ItemWithLabel } from "clearline-common";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { LoyaltyCardType, LoyaltyProgramFormData } from "../../../enroll-loyalty-widget-settings";
import { MembershipCardSettingsService } from "./membership-card-settings.service";

@Component({
  selector: "app-membership-card-settings",
  templateUrl: "./membership-card-settings.component.html",
  styleUrls: ["./membership-card-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MembershipCardSettingsService]
})
export class MembershipCardSettingsComponent implements OnInit {
  @Input() formData: LoyaltyProgramFormData;
  @Input() selectedCardTypeSetting: LoyaltyCardType;

  @Input() set membershipCardType(value: string) {
    this.cardType = LoyaltyMembershipCardType[value];
    this.isDiscountCard = this.cardType === LoyaltyMembershipCardType.Discount;
    this.isTiredCard = this.cardType === LoyaltyMembershipCardType.Tiered;
  }

  @Output() stepUpdate = new EventEmitter<Partial<LoyaltyProgramFormData>>();
  @Output() valid = new EventEmitter<boolean>();

  LoyaltyMembershipTierType = LoyaltyMembershipTierType;
  cardType: LoyaltyMembershipCardType;
  membershipTier: LoyaltyMembershipTierType;
  selectedCardIndex: number;
  isTiredCard = false;
  cardUrlList$: Observable<any[]>;
  initialTierButtonValue: LoyaltyMembershipTierType;
  tierButtons: ItemWithLabel<LoyaltyMembershipTierType>[] = [
    {
      label: `enums.LoyaltyMembershipTierType.${LoyaltyMembershipTierType.Metal}`,
      value: LoyaltyMembershipTierType.Metal
    },
    {
      label: `enums.LoyaltyMembershipTierType.${LoyaltyMembershipTierType.Diamond}`,
      value: LoyaltyMembershipTierType.Diamond
    }
  ];

  private isDiscountCard = false;

  constructor(private service: MembershipCardSettingsService) {}

  ngOnInit(): void {
    const selectedTierType = this.formData.loyaltyTierType || LoyaltyMembershipTierType.Metal;

    this.initialTierButtonValue = selectedTierType;
    this.updateMembershipTier(selectedTierType);
    this.updateCardUrls();
  }

  onSelectTier(tier: string | number): void {
    this.updateMembershipTier(tier as LoyaltyMembershipTierType);
    this.updateCardUrls();
    this.onSelectCard(null, null);
  }

  onSelectCard(cardUrl: string, index: number): void {
    this.selectedCardIndex = index;
    this.formData.cardImageUrl = cardUrl;
    this.stepUpdate.emit({ cardImageUrl: cardUrl, loyaltyTierType: this.membershipTier });
    this.checkStepComplete();
  }

  private updateMembershipTier(tier: LoyaltyMembershipTierType) {
    this.membershipTier = this.cardType === LoyaltyMembershipCardType.Tiered ? tier : null;
  }

  private updateCardUrls(): void {
    this.cardUrlList$ = this.service
      .getCardUrlList({
        cardType: this.cardType,
        tierType: this.membershipTier,
        discount: this.isDiscountCard ? (this.formData as { discount?: number }).discount : null
      })
      .pipe(
        tap((cardUrlList: string[]) => {
          const currentUrl = cardUrlList.findIndex((url) => url === this.formData.cardImageUrl);

          this.selectedCardIndex ??= currentUrl;
          this.checkStepComplete();
        })
      );
  }

  private checkStepComplete(): void {
    this.valid.emit(!!this.formData.cardImageUrl);
  }
}
