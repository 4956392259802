import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../core";
import { CfsMenuDto } from "../../dto";

@Injectable({ providedIn: "root" })
export class CfsService {
  serviceUrl = "cfs";

  constructor(private http: ApiService) {}

  getCfsMenuList(locationId: number, cashRegisterId?: number): Observable<CfsMenuDto> {
    const cashRegisterParam = cashRegisterId ? `cashRegisterId=${cashRegisterId}` : "";

    return this.http.get(`${this.serviceUrl}/menu?locationId=${locationId}&${cashRegisterParam}`);
  }
}
