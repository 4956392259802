import { LoyaltyConfigurationDto, LoyaltyRewardGetDto } from "clearline-api";
import { MembershipCardView, RewardCardView } from "../../models";

export function rewardsDtoToRewardCardList(rewards: LoyaltyRewardGetDto[] = []): RewardCardView[] {
  return rewards.map((reward: LoyaltyRewardGetDto) => ({
    imageUrl: reward.couponInfo?.couponImage || "",
    title: reward.couponInfo?.title || "",
    subTitle: reward.couponInfo?.name || "",
    campaign: reward.campaign || "",
    session: reward.session || "",
    claimed: reward.claimed
  }));
}

export function loyaltyConfigurationToMembershipCardView(
  configuration: LoyaltyConfigurationDto,
  description: string
): MembershipCardView | null {
  if (!configuration?.membership) {
    return null;
  }

  const { cardType, tierType, cardImageUrl, discount } = configuration.membership;

  return { cardType, tierType, cardImageUrl, discount, description };
}
