import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SpinnerComponent } from "@app/core/components/spinner/spinner.component";
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbProgressbarModule,
  NgbTimepickerModule,
  NgbToastModule,
  NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslocoModule } from "@ngneat/transloco";
import { FormComponentsModule } from "ui-kit";
import { CopyStringComponent } from ".";
import { DirectivesModule } from "../directives/directives.module";
import { ToasterComponent } from "./app-toaster";
import { CheckboxComponent, DropdownComponent, QuantityInputComponent, RadioComponent, RadioGroupComponent } from "./form";
import { LoaderComponent } from "./loader/loader.component";
import { PagetitleComponent } from "./pagetitle/pagetitle.component";

@NgModule({
  declarations: [
    CopyStringComponent,
    PagetitleComponent,
    LoaderComponent,
    ToasterComponent,
    SpinnerComponent,
    CheckboxComponent,
    DropdownComponent,
    RadioComponent,
    RadioGroupComponent,
    QuantityInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FormComponentsModule,
    TranslocoModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgbToastModule,
    NgbTooltipModule,
    DirectivesModule,
    NgSelectModule,
    RouterModule,
    NgbProgressbarModule
  ],
  exports: [
    FormComponentsModule,
    CopyStringComponent,
    PagetitleComponent,
    LoaderComponent,
    ToasterComponent,
    SpinnerComponent,
    CheckboxComponent,
    DropdownComponent,
    RadioComponent,
    RadioGroupComponent,
    QuantityInputComponent
  ]
})
export class UIModule {}
