export enum AllowedDeliveryChannels {
  undefined = 0,
  email = 1,
  sms = 2,
  // tslint:disable-next-line:no-bitwise
  emailAndSms = email | sms,
  qr = 64,
  hyperLink = 128,
  all = 16383
}

export enum FeatureTypeDto {
  Dashboard = "Dashboard",
  Cmc = "Cmc",
  Cfs = "Cfs",
  TrackingPixel = "TrackingPixel",
  OutboundMarketing = "OutboundMarketing",
  ReportingAndAnalytics = "ReportingAndAnalytics",
  Products = "Products",
  ExternalContacts = "ExternalContacts",
  PosIntegration = "PosIntegration",
  Connectors = "Connectors",
  CfsPrint = "CfsPrint",
  Distribution = "Distribution",
  Customers = "Customers",
  BulkView = "BulkView",
  BulkCreate = "BulkCreate",
  BulkDelete = "BulkDelete",
  AdvancedTools = "AdvancedTools",
  UrlsAndQrCodes = "UrlsAndQrCodes",
  AppsShortUrlsAndQrs = "AppsShortUrlsAndQrs",
  CustomShortUrlsAndQrs = "CustomShortUrlsAndQrs",
  BulkLinksAndQrsList = "BulkLinksAndQrsList",
  LoyaltyProgram = "LoyaltyProgram",
  LoyaltyProgramReporting = "LoyaltyProgramReporting",
  ThirdPartyAnalytics = "ThirdPartyAnalytics",
  Devices = "Devices",
  PrintableQrCodeFlyers = "PrintableQrCodeFlyers",
  PrintReceipt = "PrintReceipt",
  MarketingCampaigns = "MarketingCampaigns",
  AvailableForMenu = "AvailableForMenu",
  DigitalScreen = "DigitalScreen"
}

export enum SubscriptionAppliance {
  Account = "Account",
  Location = "Location"
}

export enum SubscriptionPlanType {
  Onboarding = "Onboarding",
  Integration = "Integration",
  ActivationBulk = "ActivationBulk"
}

export enum SubscriptionAddonType {
  Device = 1,
  Terminal,
  Printed,
  MarketingApp,
  ExternalFeature,
  BulkLinks,
  Feature
}
