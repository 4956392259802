<ng-container [ngSwitch]="templateType">
  <div
    *ngSwitchCase="CFSTemplateType.Lottie"
    class="template__wrapper"
    [class.test-mode-template]="isTestMode"
    (click)="onTemplateClick()">
    <div class="template lottie" [class.vertical-centered]="forPrint">
      <lib-cfs-template-preview
        *ngIf="(previewOptions$ | async) as previewOptions"
        [fullScreenWrapper]="true"
        [type]="'lottie'"
        [screenSize]="screenSize"
        [options]="previewOptions"
        [isPrintView]="forPrint"
        (printReady)="onLaunchPrint()">
      </lib-cfs-template-preview>
    </div>
  </div>

  <lib-video-template
    *ngSwitchCase="CFSTemplateType.Video"
    class="template__wrapper"
    [class.test-mode-template]="isTestMode"
    [previewType]="previewType"
    [mediaConfig]="fullMediaConfig"
    [innerHTML]="generatedBody"
    [templateProperties]="templateProperties"
    [displayTime]="displayTime"
    [index]="index"
    (ready)="onVideoTemplateReady()"
    (click)="onTemplateClick()">
  </lib-video-template>

  <div
    *ngSwitchDefault
    class="template__wrapper"
    [class.for-print]="forPrint"
    [class.test-mode-template]="isTestMode"
    [innerHTML]="generatedBody"
    (click)="onTemplateClick()"
  ></div>
</ng-container>

<div *ngIf="isTestMode" class="test-mode-info">
  <span *ngIf="index >= 0">Index: {{ index }}; </span>
  <span *ngIf="title">Template name: {{ title }}; </span>
  <span *ngIf="contentDuration">Content duration: {{ contentDuration / 1000 }}; </span>
  <span *ngIf="displayTime">Display Time: {{ displayTime / 1000 }}; </span>
</div>
