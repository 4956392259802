<div [formGroup]="form" class="wizard-content-modal p-lg-5" *transloco="let t; read: 'widgets.enrollLoyalty'">
  <div class="col-xl-2 col-lg-12"></div>

  <lib-textarea
    class="col-md-12"
    [control]="form.controls.description"
    [label]="'common.controls.description' | transloco"
    [placeholder]="'common.controls.description' | transloco"
    [rows]="3">
  </lib-textarea>

  <ng-container *ngIf="isDiscountCard">
    <div class="row">
      <app-dropdown
        class="col-md-6 col-sm-12 text-start"
        bindLabel="name"
        bindValue="n"
        [control]="form.controls.discount"
        [isRequired]="true"
        [items]="selectedCardTypeSetting.list"
        [label]="t('discount')"
        [placeholder]="t('discount')"
      ></app-dropdown>
    </div>

    <app-min-requirements
      formControlName="conditionConfiguration"
      [isRequired]="false"
    ></app-min-requirements>
  </ng-container>
</div>
