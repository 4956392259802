import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoyaltyExpirationPeriod } from "@app/models";
import { Unsubscriber } from "@app/shared/components";
import { positiveIntegerValidator } from "@app/shared/validators";
import { ItemWithLabel } from "clearline-common";
import { debounceTime, startWith } from "rxjs/operators";
import { maxTextAreaInputLength } from "ui-kit";
import {
  LoyaltyCalculationConfiguration,
  LoyaltyCalculationMode,
  LoyaltyCardType,
  LoyaltyCardTypes,
  LoyaltyConditionConfiguration,
  LoyaltyMinimumRequirementMode,
  LoyaltyOnboardingRequest,
  LoyaltyProgramFormData
} from "../../../enroll-loyalty-widget-settings";

@Component({
  selector: "app-loyalty-program-settings",
  templateUrl: "./loyalty-program-settings.component.html",
  styleUrls: ["./loyalty-program-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoyaltyProgramSettingsComponent extends Unsubscriber implements OnInit {
  @Input() selectedCardTypeSetting: LoyaltyCardType;
  @Input() expirationPeriodOptions: ItemWithLabel<number>[] = [];
  @Input() expirationPeriodTitle = "";
  @Input() formData: LoyaltyProgramFormData;

  @Output() reset = new EventEmitter<void>();
  @Output() stepComplete = new EventEmitter<LoyaltyOnboardingRequest>();
  @Output() stepUpdate = new EventEmitter<LoyaltyProgramFormData>();
  @Output() valid = new EventEmitter<boolean>();

  readonly baseInputValidators = [Validators.required, Validators.min(1)];
  readonly minReqRadioButtons = [
    { label: "widgets.enrollLoyalty.minAmount", value: LoyaltyMinimumRequirementMode.Amount, disabled: false },
    { label: "widgets.enrollLoyalty.minCount", value: LoyaltyMinimumRequirementMode.Count, disabled: false }
  ];

  LoyaltyCardTypes = LoyaltyCardTypes;
  form = new FormGroup({
    description: new FormControl(null, [Validators.maxLength(maxTextAreaInputLength)]),
    loyaltyType: new FormControl(null, [Validators.required]),
    loyaltyCardId: new FormControl("", [Validators.required]),
    goalBalance: new FormControl(null, [Validators.required]),
    expirationPeriod: new FormControl<number>(null, [Validators.required]),
    startingStamps: new FormControl(null, [Validators.required]),
    maxPerDay: new FormControl(null, [...this.baseInputValidators, positiveIntegerValidator]),
    rewards: new FormControl([]),
    calculationMode: new FormControl<LoyaltyCalculationMode>({ value: null, disabled: false }, [Validators.required]),
    conditionConfiguration: new FormControl<LoyaltyConditionConfiguration>(null),
    count: new FormControl<number>({ value: null, disabled: true }, [...this.baseInputValidators]),
    amount: new FormControl<number>({ value: null, disabled: true }, [...this.baseInputValidators])
  });

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.form.patchValue(this.formData, { emitEvent: false });
    this.updateAutomaticCalculationControls(this.formData.loyaltyType);

    this.sub = this.form.controls.goalBalance.valueChanges.subscribe((value: number) => {
      this.form.controls.maxPerDay.setValidators([...this.baseInputValidators, positiveIntegerValidator, Validators.max(value)]);
      this.form.controls.maxPerDay.updateValueAndValidity({ emitEvent: false });
      this.reset.emit();
    });

    this.sub = this.form.controls.calculationMode.valueChanges
      .pipe(startWith(this.form.controls.calculationMode.value))
      .subscribe((value: LoyaltyCalculationMode) => {
        switch (value) {
          case LoyaltyCalculationMode.Points: {
            this.form.controls.count.setValidators([...this.baseInputValidators, positiveIntegerValidator]);
            this.form.controls.amount.setValidators([...this.baseInputValidators]);
            this.form.controls.count.enable({ emitEvent: false });
            this.form.controls.amount.enable({ emitEvent: false });
            break;
          }
          case LoyaltyCalculationMode.Purchase: {
            this.disableCalculationInputs();
            break;
          }
        }
      });

    this.sub = this.form.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      const formData: LoyaltyProgramFormData = this.form.getRawValue() as unknown as LoyaltyProgramFormData;

      this.stepUpdate.emit(formData);

      if (this.form.valid) {
        this.stepComplete.emit(this.getLoyaltyProgramDetailsResult());
      }

      this.valid.emit(this.form.valid);
    });
  }

  changeSelectGoalBalance(value) {
    if (!value) {
      this.form.controls.goalBalance.reset(null, { emitEvent: false });
    } else {
      this.changeMaxPerDay();
    }
  }

  changeSelectExpirationPeriod(value: number) {
    if (!value) {
      this.form.controls.expirationPeriod.reset(null, { emitEvent: false });
    }
  }

  changeSelectStartingStamps(value) {
    if (!value) {
      this.form.controls.startingStamps.reset(null, { emitEvent: false });
    }
  }

  private changeMaxPerDay() {
    if (this.form.controls.maxPerDay.value > this.form.controls.goalBalance.value) {
      this.form.controls.maxPerDay.setValue(this.form.controls.goalBalance.value);
    }
  }

  private disableCalculationInputs() {
    this.form.controls.count.disable({ emitEvent: false });
    this.form.controls.amount.disable({ emitEvent: false });
    this.form.controls.amount.clearValidators();
    this.form.controls.count.clearValidators();
  }

  private updateAutomaticCalculationControls(id: LoyaltyCardTypes) {
    const isStampCard = id === LoyaltyCardTypes.Stampcard;
    const calculationMode = isStampCard ? LoyaltyCalculationMode.Purchase : LoyaltyCalculationMode.Points;

    this.disableCalculationInputs();

    if (isStampCard) {
      this.form.controls.count.setValue(calculationMode, { emitEvent: true });
    }
    this.form.controls.calculationMode.setValue(calculationMode, { emitEvent: true });
  }

  private getLoyaltyProgramDetailsResult(): LoyaltyOnboardingRequest {
    const formData = this.form.getRawValue();
    const { count, amount, calculationMode, expirationPeriod, loyaltyType, ...rest } = formData;
    const calculationConfiguration: LoyaltyCalculationConfiguration = {
      count,
      amount,
      calculationMode
    };
    const expiration = {
      period: expirationPeriod === 0 ? LoyaltyExpirationPeriod.Never : LoyaltyExpirationPeriod.Month
    };

    if (expirationPeriod > 0) {
      expiration["value"] = expirationPeriod;
    }

    return {
      ...rest,
      calculationConfiguration,
      expiration,
      loyaltyType,
      isAutomaticCalculationEnabled: false
    };
  }
}
