import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Unsubscriber } from "@app/shared/components";
import { LoyaltyMembershipCardType } from "clearline-api";
import { debounceTime } from "rxjs/operators";
import { maxTextAreaInputLength } from "ui-kit";
import { LoyaltyCardType, LoyaltyConditionConfiguration, LoyaltyProgramFormData } from "../../../enroll-loyalty-widget-settings";

@Component({
  selector: "app-membership-program-settings",
  templateUrl: "./membership-program-settings.component.html",
  styleUrls: ["./membership-program-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembershipProgramSettingsComponent extends Unsubscriber implements OnInit {
  @Input() formData: LoyaltyProgramFormData;
  @Input() selectedCardTypeSetting: LoyaltyCardType;

  @Input() set membershipCardType(value: string) {
    this.isDiscountCard = LoyaltyMembershipCardType[value] === LoyaltyMembershipCardType.Discount;
  }

  @Output() stepComplete = new EventEmitter<LoyaltyProgramFormData>();
  @Output() stepUpdate = new EventEmitter<LoyaltyProgramFormData>();
  @Output() valid = new EventEmitter<boolean>();

  isDiscountCard = false;
  form = new FormGroup({
    description: new FormControl<string>(null, [Validators.maxLength(maxTextAreaInputLength)]),
    discount: new FormControl<number>(null),
    conditionConfiguration: new FormControl<LoyaltyConditionConfiguration>(null)
  });

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.form.patchValue(this.formData, { emitEvent: false });

    if (this.isDiscountCard) {
      const discount = this.formData.discount || this.selectedCardTypeSetting.list[0]?.n || 0;

      this.form.controls.discount.setValue(discount, { emitEvent: false });
      this.form.controls.discount.setValidators([Validators.required]);
      this.form.controls.conditionConfiguration.setValidators([Validators.required]);
    }

    this.sub = this.form.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      if (this.form.valid) {
        this.stepComplete.emit(this.getProgramDetailsResult());
      } else {
        this.stepUpdate.emit(this.getProgramDetailsResult());
      }
      this.valid.emit(this.form.valid);
    });

    this.valid.emit(this.form.valid);
  }

  private getProgramDetailsResult(): LoyaltyProgramFormData {
    return this.form.getRawValue() as LoyaltyProgramFormData;
  }
}
