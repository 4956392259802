import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LoyaltyMembershipCardType } from "clearline-api";
import { LoyaltyOnboardingRequest, MembershipProgramOnboardingRequest } from "../../../enroll-loyalty-widget-settings";

@Component({
  selector: "app-membership-program-details",
  templateUrl: "./membership-program-details.component.html",
  styleUrls: ["./membership-program-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembershipProgramDetailsComponent {
  @Input() set programRequest(programRequest: LoyaltyOnboardingRequest) {
    this.onboardingRequest = programRequest as MembershipProgramOnboardingRequest;
  }

  LoyaltyMembershipCardType = LoyaltyMembershipCardType;
  onboardingRequest: MembershipProgramOnboardingRequest = null;
}
