export enum NotificationAction {
  InformUser = 1,
  BlockAccount = 2,
  AddActionLink = 4
}

export enum NotificationSeverity {
  Info,
  Warning,
  Error
}
