<div [formGroup]="form" class="wizard-content-modal p-lg-5"  *transloco="let tr; read: 'widgets.enrollLoyalty'">
  <div class="col-xl-2 col-lg-12"></div>

  <lib-textarea
    class="col-md-12"
    [control]="form.controls.description"
    [label]="'common.controls.description' | transloco"
    [placeholder]="'common.controls.description' | transloco"
    [rows]="3">
  </lib-textarea>

  <div>
    <div class="row">
      <app-dropdown
        class="col-md-6 col-sm-12 text-start"
        bindLabel="name"
        bindValue="n"
        [control]="form.controls.goalBalance"
        [isRequired]="true"
        [items]="selectedCardTypeSetting.list"
        [label]="tr('labelGoalBalance')"
        [placeholder]="tr('labelGoalBalance')"
        (change)="changeSelectExpirationPeriod($event)"
      ></app-dropdown>

      <app-input
        class="col-md-6 col-sm-12"
        type="number"
        [control]="form.controls.maxPerDay"
        [label]="tr('labelMaxPerDayGeneric')"
        [isRequired]="true"
      ></app-input>

      <app-dropdown
        class="col-md-6 col-sm-12 text-start"
        bindLabel="name"
        bindValue="n"
        [control]="form.controls.startingStamps"
        [isRequired]="true"
        [items]="selectedCardTypeSetting.startingList"
        [label]="tr('labelStartingStamps')"
        [placeholder]="tr('labelStartingStamps')"
        (change)="changeSelectStartingStamps($event)"
      ></app-dropdown>

      <app-dropdown
        class="col-md-6 col-sm-12 text-start"
        bindLabel="label"
        bindValue="value"
        [control]="form.controls.expirationPeriod"
        [isRequired]="true"
        [items]="expirationPeriodOptions"
        [label]="expirationPeriodTitle"
        [placeholder]="expirationPeriodTitle"
        (change)="changeSelectExpirationPeriod($event)"
      ></app-dropdown>
    </div>
  </div>

  <div *ngIf="form.controls.loyaltyType.value !== LoyaltyCardTypes.Stampcard">
    <h5>{{ tr('labelCurrencyToDollars') }}</h5>
    <div class="mb-2">{{ tr('labelCurrencyToDollarsDescription') }}</div>

    <div class="col-md-6 col-sm-12">
      <div class="d-flex gap-2">
        <app-input
          type="number"
          [control]="form.controls.amount"
          [label]="tr('labelDollars')"
          [placeholder]="tr('labelDollars')"
        ></app-input>

        <div class="align-self-center">=</div>

        <app-input
          type="number"
          [control]="form.controls.count"
          [label]="tr('labelPoints')"
          [placeholder]="tr('labelPoints')"
        ></app-input>
      </div>
    </div>
  </div>

  <app-min-requirements formControlName="conditionConfiguration"></app-min-requirements>
</div>
