<div *ngIf="!!menuInfo" class="custom">
  <div *transloco="let t; read i18nScope" class="modal-header with-subtitle">
    <h5 class="modal-title cfs-menu-title">
      <span>{{ menuInfo.brandName }}</span> {{ t("title") }}
    </h5>

    <p class="modal-subtitle m-0">{{ t("subtitle") }}</p>

    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onCancel()"></button>
  </div>

  <div class="modal-body">
    <div class="menu">
      <div
        *ngFor="let menuItem of menuInfo.items"
        class="menu-item"
        [class.disabled]="!menuItem.pageUrl"
        (click)="onMenuItemClick(menuItem)">
        <div class="menu-item__content">
          <img [src]="menuItem.icon" alt="icon" class="menu-item__icon" />
          <span class="menu-item__title">{{ menuItem.title }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
