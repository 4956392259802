<div *transloco="let t; read: 'widgets.enrollLoyalty'">
  <!--PROGRAM TYPES -->
  <div class="d-flex flex-column flex-lg-row gap-3" *ngIf="cardTypesSetting">
    <div class="program-type-button" *ngFor="let item of cardTypesSetting">
      <button
        class="btn btn-outline-light btn-card d-flex align-items-center"
        tooltipClass="tooltip-card-type"
        triggers="hover"
        [ngbTooltip]="item.tooltip | transloco"
        [autoClose]="true"
        [ngClass]="item.id === selectedProgramTypeId ? 'active' : ''"
        (click)="onProgramTypeChange(item)"
      >
        <i class="icon-{{ item.id }}"></i> {{ item.name | transloco }}
      </button>
    </div>
  </div>

  <!--CARD TYPES -->
  <div class="row wizard-content-modal mt-4">
    <div class="col-md-12 text-start font-size-16 font-bold">{{ t('chooseCardStyle') }}</div>
    <app-loyalty-cards
      class="mt-n5"
      [loyaltyCards]="cardList"
      [selectedCard]="selectedCard"
      [loading]="loading"
      (selectChanged)="onLoyaltyCardChange($event)"
    >
    </app-loyalty-cards>
  </div>
</div>
