import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
  CfsTemplateMediaConfig,
  CFSTemplateType,
  CfsTemplateUtils,
  ContentSettings,
  defaultCfsTemplateMediaConfig,
  Template,
  TemplatePreviewOptions,
  TemplatePreviewType,
  TemplateProperties,
  UrlContentSettings,
  VimeoPlayerComponent
} from "clearline-common";
import { BehaviorSubject, of } from "rxjs";
import { CommonModule } from "@angular/common";
import { CfsTemplatePreviewComponent } from "./cfs-template-preview";
import { TemplatePreviewService } from "./template-preview.service";
import { take } from "rxjs/operators";
import { DigitalAssetGetDto, DigitalAssetScreenSize } from "clearline-api";
import { VideoTemplateComponent } from "./video-template";

@Component({
  selector: "lib-cfs-template",
  templateUrl: "./cfs-template.component.html",
  styleUrls: ["./cfs-template.component.scss"],
  standalone: true,
  imports: [CommonModule, CfsTemplatePreviewComponent, VideoTemplateComponent, VimeoPlayerComponent]
})
export class CfsTemplateComponent implements AfterViewInit {
  @Input() isTestMode = false;
  @Input() index = 0;
  @Input() previewType: TemplatePreviewType = TemplatePreviewType.Active;
  @Input() forPrint = false;
  @Input() isLowResolutionScreen = false;

  @Input() set mediaConfig(config: CfsTemplateMediaConfig) {
    this.fullMediaConfig = { ...config, fullscreen: !this.isTestMode, coverParent: this.isTestMode };
  }

  @Input() set template(template: Template) {
    if (template) {
      const { name, generatedBody, configuration, templateProperties } = <Template>template;

      this.generatedBody = generatedBody ? this.sanitizer.bypassSecurityTrustHtml(generatedBody as string) : "";
      this.templateType = this.getTemplateType(generatedBody as string);
      this.title = name;
      this.displayTime = configuration?.options?.displayTime ?? 0;
      this.templateProperties = templateProperties;
      this.contentDuration = Number((this.templateProperties as UrlContentSettings | undefined)?.ContentDuration) ?? 0;

      if (this.templateType === CFSTemplateType.Lottie) {
        this.handleLottie(template as Template);
      }
    }
  }

  @Output() print = new EventEmitter();
  @Output() templateClick = new EventEmitter();
  @Output() ready = new EventEmitter();

  @ViewChild(VideoTemplateComponent) videoTemplateComponent?: VideoTemplateComponent;

  readonly CFSTemplateType = CFSTemplateType;
  private readonly smallScreen = 768;
  private isScreenSizeResponsive = false;

  fullMediaConfig: CfsTemplateMediaConfig = defaultCfsTemplateMediaConfig;
  templateProperties?: TemplateProperties;
  title = "";
  displayTime = 0;
  contentDuration = 0;
  generatedBody: SafeHtml = "";
  screenSize?: DigitalAssetScreenSize;
  templateType?: CFSTemplateType | null;
  previewOptions$ = new BehaviorSubject<TemplatePreviewOptions | null>(null);
  contentSettings: ContentSettings | undefined;
  digitalAsset: DigitalAssetGetDto | undefined;

  @HostListener("window:resize") handleResizeEvent(): void {
    this.setScreenSize();
  }

  constructor(private sanitizer: DomSanitizer, private templatePreviewService: TemplatePreviewService) {}

  ngAfterViewInit(): void {
    if (this.templateType !== CFSTemplateType.Video) {
      this.ready.emit();
    }
  }

  onVideoTemplateReady(): void {
    this.ready.emit();
  }

  onTemplateClick() {
    this.templateClick.emit();
  }

  onLaunchPrint(): void {
    if (this.forPrint) {
      this.print.emit();
    }
  }

  play(): void {
    if (this.videoTemplateComponent) {
      this.videoTemplateComponent.play();
    }
  }

  stop(): void {
    if (this.videoTemplateComponent) {
      this.videoTemplateComponent.stop();
    }
  }

  private handleLottie(template: Template): void {
    const previewOptions$ = template?.previewOptions ? of(template.previewOptions) : this.templatePreviewService.getLottieOptions(template);

    previewOptions$.pipe(take(1)).subscribe((previewOptions: TemplatePreviewOptions | null) => {
      const { mediaContent } = previewOptions || {};
      this.isScreenSizeResponsive = CfsTemplateUtils.getIsMediaScreenSizeResponsive(mediaContent);

      this.previewOptions$.next(previewOptions);
      this.setScreenSize();
    });
  }

  private setScreenSize(): void {
    const updatedScreenSize: DigitalAssetScreenSize = this.getScreenSizeType();

    if (this.screenSize !== updatedScreenSize) {
      this.screenSize = updatedScreenSize;
    }
  }

  private getScreenSizeType(): DigitalAssetScreenSize {
    let updatedScreenSize: DigitalAssetScreenSize = DigitalAssetScreenSize.Full;

    if (this.isScreenSizeResponsive && !this.isLowResolutionScreen) {
      if (window.innerWidth <= this.smallScreen) {
        updatedScreenSize = DigitalAssetScreenSize.Compact;
      } else if (window.innerHeight >= window.innerWidth) {
        updatedScreenSize = DigitalAssetScreenSize.Vertical;
      }
    }

    return updatedScreenSize;
  }

  private getTemplateType(generatedBody: string): CFSTemplateType | null {
    const temporaryDiv: HTMLElement = document.createElement("div");
    temporaryDiv.innerHTML = generatedBody;
    const templateElement: HTMLElement | null = temporaryDiv.querySelector(".template");

    if (templateElement) {
      const classList: DOMTokenList = templateElement.classList;

      for (const type in CFSTemplateType) {
        if (classList.contains(CFSTemplateType[type as keyof typeof CFSTemplateType])) {
          return CFSTemplateType[type as keyof typeof CFSTemplateType];
        }
      }
    }

    return null;
  }
}
