import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { LoadingService } from "@app/core";
import { expirationPeriodsList, IndustryLoyaltyCard, LoyaltyExpirationPeriod, LoyaltyProgramExpirationDto } from "@app/models";
import { LoyaltyCardService } from "@app/services";
import { Unsubscriber } from "@app/shared/components";
import { TranslocoService } from "@ngneat/transloco";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { WizardOptions, WizardStepItem } from "ui-kit";
import {
  baseMembershipCard,
  emptyLoyaltyProgramFormData,
  LoyaltyCardType,
  LoyaltyCardTypes,
  loyaltyCardTypesSettings,
  LoyaltyOnboardingRequest,
  LoyaltyProgramFormData,
  LoyaltyRewardItem,
  MembershipProgramOnboardingRequest,
  programFormDataToMembershipOnboardingRequest,
  SelectedLoyaltyProgramData
} from "../../../enroll-loyalty-widget-settings";
import { getLoyaltyProgramExpirationPeriodTitle } from "../../../enroll-loyalty-widget-settings.utils";

@Component({
  selector: "app-loyalty-wizard",
  templateUrl: "./loyalty-wizard.component.html",
  styleUrls: ["./loyalty-wizard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoyaltyWizardComponent extends Unsubscriber implements OnInit {
  @Input() industryId = 0;
  @Input() locationId: number;
  @Input() enrollLoyaltyLink: string;

  @Output() modalClose = new EventEmitter<void>();
  @Output() resultChange = new EventEmitter<LoyaltyOnboardingRequest | MembershipProgramOnboardingRequest>();

  readonly expirationPeriodOptions$ = this.loyaltyCardService.getExpirationList().pipe(
    map((options: LoyaltyProgramExpirationDto[]) =>
      options.map(({ period, value }) => {
        const i18nKey = `enums.LoyaltyExpirationPeriod.${period}`;

        return period === LoyaltyExpirationPeriod.Never
          ? { label: this.transloco.translate(i18nKey), value: 0 }
          : { label: `${value} ${this.transloco.translate(i18nKey)}`, value };
      })
    )
  );

  LoyaltyCardTypes = LoyaltyCardTypes;
  selectedProgram: SelectedLoyaltyProgramData | null = null;
  expirationPeriodTitle = "";
  rewardProgramFormData: LoyaltyProgramFormData;
  rewardsList: LoyaltyRewardItem[] = [];
  loyaltyProgramDetailsResult: LoyaltyOnboardingRequest;
  nothingFound = false;
  isMembershipCard = false;
  expirationPeriodsList = expirationPeriodsList;
  selectedCardTypeSetting: LoyaltyCardType;
  wizardOptions: WizardOptions = {
    isModalWindow: true,
    button: {
      complete: { label: this.transloco.translate("common.controls.save") },
      previous: { label: this.transloco.translate("common.controls.back") }
    }
  };
  steps: WizardStepItem[] = [];

  industryList$: Observable<IndustryLoyaltyCard[]>;

  constructor(public loadingSvc: LoadingService, private loyaltyCardService: LoyaltyCardService, private transloco: TranslocoService) {
    super();
  }

  ngOnInit(): void {
    this.steps = [
      {
        label: this.transloco.translateObject("widgets.enrollLoyalty.step1"),
        hidden: false,
        stepControl: new FormControl<boolean>(false, Validators.requiredTrue)
      },
      {
        label: this.transloco.translateObject("widgets.enrollLoyalty.step2"),
        hidden: false,
        stepControl: new FormControl<boolean>(false, Validators.requiredTrue)
      },
      {
        label: this.getStepLabel(2),
        hidden: false,
        stepControl: new FormControl<boolean>(false, Validators.requiredTrue)
      },
      {
        label: this.transloco.translateObject("widgets.enrollLoyalty.step4"),
        hidden: false,
        stepControl: new FormControl<boolean>(false, Validators.requiredTrue)
      }
    ];

    this.industryList$ = combineLatest([
      this.loyaltyCardService.getIndustryLoyaltyCards(this.industryId),
      this.loyaltyCardService
        .getMembershipCardTypes()
        .pipe(map((list) => list.map(({ title, cardType, imageUrl }) => baseMembershipCard(title, cardType, imageUrl))))
    ]).pipe(
      map(([loyaltyCards, membershipCards]: [IndustryLoyaltyCard[], IndustryLoyaltyCard[]]) => [...loyaltyCards, ...membershipCards])
    );
  }

  resetProgram() {
    this.selectedProgram = null;
    this.updateStep1Validity();
  }

  onSelectChanged(selectedProgram: SelectedLoyaltyProgramData) {
    this.selectedProgram = selectedProgram;
    this.rewardProgramFormData = emptyLoyaltyProgramFormData();

    this.updateLoyaltyProgramFormData({
      loyaltyType: selectedProgram.loyaltyType,
      loyaltyCardId: selectedProgram.loyaltyCard.id
    });

    this.isMembershipCard = this.selectedProgram?.loyaltyType === LoyaltyCardTypes.Membershipcard;
    this.selectedCardTypeSetting = loyaltyCardTypesSettings.find((o) => o.id === this.selectedProgram?.loyaltyType);
    this.expirationPeriodTitle = getLoyaltyProgramExpirationPeriodTitle(this.selectedProgram?.loyaltyType, this.transloco);

    this.updateWizardSteps();
    this.updateStep1Validity();
  }

  onRewardsUpdate(rewards: LoyaltyRewardItem[]): void {
    this.loyaltyProgramDetailsResult = {
      ...this.loyaltyProgramDetailsResult,
      rewards
    };

    this.updateFinalStepsValidity();
  }

  onStepChange(stepIndex: number): void {
    if (stepIndex > 1 && !this.isMembershipCard) {
      this.updateFinalStepsValidity();
    }
    if (stepIndex === 2 && this.isMembershipCard) {
      this.updateFinalStepsValidity();
      this.updateStepValidity(3, true);
    }
    if (stepIndex === 3 && this.isMembershipCard) {
      this.updateMembershipOnboardingRequest();
    }
  }

  saveResult(): void {
    if (this.isMembershipCard) {
      this.saveMembershipProgramResult();
    } else {
      this.saveRewardProgramResult();
    }
  }

  onResetProgramSettings(): void {
    this.resetRewards();
  }

  updateProgramDetailsResult(result: LoyaltyOnboardingRequest): void {
    this.loyaltyProgramDetailsResult = result;

    if (!this.isMembershipCard) {
      this.steps[1].stepControl.setValue(!!result);
    }
  }

  updateLoyaltyProgramFormData(data: Partial<LoyaltyProgramFormData>): void {
    this.rewardProgramFormData = {
      ...this.rewardProgramFormData,
      ...data
    };
  }

  updateStepValidity(stepIndex: number, isValid: boolean): void {
    this.steps[stepIndex].stepControl.setValue(isValid);
  }

  private updateStep1Validity(): void {
    this.steps.forEach((step) => step.stepControl.setValue(false, { emitEvent: false }));
    this.steps[0].stepControl.setValue(!!this.selectedProgram);
  }

  private updateFinalStepsValidity(): void {
    this.steps[2].stepControl.setValue(this.rewardsList.length > 0);
    this.steps[3].stepControl.setValue(this.steps[2].stepControl.value);
  }

  private resetRewards(): void {
    this.rewardsList = [];
    this.steps[2].stepControl.setValue(false);
    this.steps[3].stepControl.setValue(false);
  }

  private updateWizardSteps(): void {
    this.steps[2].label = this.getStepLabel(2);
  }

  private getStepLabel(stepIndex: number): string {
    return this.transloco.translateObject(`widgets.enrollLoyalty.step${stepIndex + 1}-${this.isMembershipCard ? "membership" : "loyalty"}`);
  }

  private saveRewardProgramResult(): void {
    if (this.loyaltyProgramDetailsResult) {
      this.resultChange.emit(this.loyaltyProgramDetailsResult);
    }
  }

  private saveMembershipProgramResult(): void {
    if (this.loyaltyProgramDetailsResult) {
      this.resultChange.emit(this.loyaltyProgramDetailsResult);
    }
  }

  private updateMembershipOnboardingRequest() {
    const result: MembershipProgramOnboardingRequest = programFormDataToMembershipOnboardingRequest(
      this.rewardProgramFormData,
      this.selectedProgram
    );

    this.loyaltyProgramDetailsResult = result as LoyaltyOnboardingRequest;
  }
}
