import { CfsTemplateMediaConfig } from "clearline-common";

export const defaultCfsTemplateMediaConfig: CfsTemplateMediaConfig = {
  muted: true,
  autoplay: false,
  loop: false,
  fullscreen: false,
  coverParent: false
};

export const mediaReadyDelay = 1000;
