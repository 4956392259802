import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { LoyaltyCard } from "@app/models";
import { TranslocoService } from "@ngneat/transloco";
import {
  LoyaltyCalculationMode,
  LoyaltyCardType,
  LoyaltyCardTypes,
  LoyaltyOnboardingRequest,
  LoyaltyRewardItem
} from "../../../enroll-loyalty-widget-settings";
import {
  getLoyaltyProgramExpirationPeriodLabel,
  getLoyaltyProgramExpirationPeriodTitle
} from "../../../enroll-loyalty-widget-settings.utils";

@Component({
  selector: "app-loyalty-program-details",
  templateUrl: "./loyalty-program-details.component.html",
  styleUrls: ["./loyalty-program-details.component.scss"]
})
export class LoyaltyProgramDetailsComponent implements OnInit {
  @Input() loyaltyProgramDetailsResult: LoyaltyOnboardingRequest;
  @Input() selectedCard: LoyaltyCard;
  @Input() selectedCardTypeSetting: LoyaltyCardType;
  @Input() rewardsList: LoyaltyRewardItem[];
  @Input() enrollLoyaltyLink: string;
  @Input() expirationPeriodLabel = "";

  LoyaltyCalculationMode = LoyaltyCalculationMode;
  LoyaltyCardTypes = LoyaltyCardTypes;

  expirationPeriod = "";
  expirationPeriodTitle = "";
  isPointCard = false;
  isStampCard = false;

  constructor(private changeDetectorRef: ChangeDetectorRef, private transloco: TranslocoService) {}

  ngOnInit(): void {
    this.expirationPeriod = getLoyaltyProgramExpirationPeriodLabel(this.loyaltyProgramDetailsResult?.expiration, this.transloco);
    this.expirationPeriodTitle = getLoyaltyProgramExpirationPeriodTitle(this.loyaltyProgramDetailsResult?.loyaltyType, this.transloco);
    this.isPointCard = this.loyaltyProgramDetailsResult.loyaltyType === LoyaltyCardTypes.Pointcard;
    this.isStampCard = this.loyaltyProgramDetailsResult.loyaltyType === LoyaltyCardTypes.Stampcard;
    this.changeDetectorRef.markForCheck();
  }

  copyUrl() {
    const content = (document.getElementById("program-link-copy") as HTMLElement).innerText;
    navigator["clipboard"]
      .writeText(content)
      .then()
      .catch((e) => console.error(e));
  }
}
