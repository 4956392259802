import {
  discountCardList,
  IndustryLoyaltyCard,
  LoyaltyCard,
  LoyaltyExpirationPeriod,
  pointsCardList,
  rewardsCardList,
  stampCardList,
  startingPointsCardList,
  startingRewardsCardList,
  startingStampCardList
} from "@app/models";
import { LoyaltyMembershipCardType, LoyaltyMembershipTierType } from "clearline-api";
import { IIsKioskEnabled } from "../widget-settings-base";

export class EnrollLoyaltyWidgetSettings implements IIsKioskEnabled {
  // public loyaltyType: LoyaltyType;
  public maxStampsPoints?: number;
  // public isLimitPerClient: boolean;
  public loyaltyProgram: LoyaltyProgram;
  public enrollLoyaltyLink: string;
  public loyaltyCardId?: string;
  public onboardingRequest: LoyaltyOnboardingRequest;
  IsKioskEnabled: boolean;
}

export enum LoyaltyCalculationMode {
  Points = 0,
  Purchase = 1
}

export enum LoyaltyMinimumRequirementMode {
  Count = "minimumCount",
  Amount = "minimumAmount"
}

export enum LoyaltyType {
  AllLocations = 0,
  SingleLocation = 1,
  MultiLocations = 2
}

export enum LoyaltyProgram {
  DigitalStampCard = 0,
  PointsToPrize = 1,
  RewardsCard = 2,
  MyCard = 3,
  MembershipCard = 4
}

export interface LoyaltyExpirationPeriodData {
  period: LoyaltyExpirationPeriod;
  value?: number;
}

export interface LoyaltyOnboardingRequest extends MembershipProgramOnboardingRequest {
  loyaltyCardId: string;
  goalBalance?: number;
  startingStamps?: number;
  maxPerDay?: number;
  rewards?: LoyaltyReward[];
  isAutomaticCalculationEnabled: boolean;
  calculationConfiguration: LoyaltyCalculationConfiguration;
  expiration: LoyaltyExpirationPeriodData;
}

export interface MembershipProgramOnboardingRequest {
  configuration?: {
    membership: {
      cardType: LoyaltyMembershipCardType;
      tierType: LoyaltyMembershipTierType;
      discount: number;
      cardImageUrl: string;
    };
  };
  conditionConfiguration?: LoyaltyConditionConfiguration;
  description: string;
  loyaltyType: number;
}

export interface LoyaltyCalculationConfiguration {
  count: number; // integer
  amount: number;
  calculationMode: LoyaltyCalculationMode;
}

export interface LoyaltyConditionConfiguration {
  // only one of the following should be set
  minimumCount?: number; // integer
  minimumAmount?: number;
}

export class LoyaltyReward {
  public tier: number;
  public couponId: string;
}

export class LoyaltyRewardItem {
  public name: string;
  public tier: number;
  public couponId: string;
  public couponName: string;
}

export enum LoyaltyCardTypes {
  Pointcard = 1,
  Stampcard = 2,
  Rewardcard = 3,
  Membershipcard = 4
}

export interface LoyaltyCardType {
  id: number;
  name: string;
  suffixList: string;
  list: ListType[];
  startingList: ListType[];
  tooltip: string;
  sortOrder: number;
}

export interface SelectedLoyaltyProgramData {
  loyaltyCard: LoyaltyCard;
  loyaltyType: number;
}

export interface LoyaltyProgramFormData {
  amount: number;
  cardImageUrl?: string; // for membership card
  calculationMode?: number;
  conditionConfiguration?: LoyaltyConditionConfiguration;
  count?: number;
  description: string;
  discount?: number; // for membership card
  expirationPeriod?: number;
  goalBalance?: number;
  loyaltyCardId?: string;
  loyaltyType?: LoyaltyCardTypes;
  loyaltyTierType?: LoyaltyMembershipTierType; // for membership card
  maxPerDay?: number;
  rewards?: LoyaltyReward[];
  startingStamps?: number;
}

export interface ListType {
  n: number;
  name: string;
}

export const loyaltyCardTypesSettings: LoyaltyCardType[] = [
  {
    id: LoyaltyCardTypes.Pointcard,
    name: "widgets.enrollLoyalty.pointcardName",
    suffixList: "point",
    list: pointsCardList,
    startingList: startingPointsCardList,
    tooltip: "widgets.enrollLoyalty.pointcardTooltip",
    sortOrder: 2
  },

  {
    id: LoyaltyCardTypes.Stampcard,
    name: "widgets.enrollLoyalty.stampcardName",
    suffixList: "stamp",
    list: stampCardList,
    startingList: startingStampCardList,
    tooltip: "widgets.enrollLoyalty.stampcardTooltip",
    sortOrder: 1
  },

  {
    id: LoyaltyCardTypes.Rewardcard,
    name: "widgets.enrollLoyalty.rewardcardName",
    suffixList: "point",
    list: rewardsCardList,
    startingList: startingRewardsCardList,
    tooltip: "widgets.enrollLoyalty.rewardcardTooltip",
    sortOrder: 3
  },

  {
    id: LoyaltyCardTypes.Membershipcard,
    name: "widgets.enrollLoyalty.membershipcardName",
    suffixList: "",
    list: discountCardList,
    startingList: [],
    tooltip: "widgets.enrollLoyalty.membershipcardTooltip",
    sortOrder: 4
  }
];

export const baseMembershipCard = (name: string, cardType: LoyaltyMembershipCardType, imgUrl: string): IndustryLoyaltyCard => ({
  id: null,
  industryId: 4,
  loyaltyType: LoyaltyCardTypes.Membershipcard,
  loyaltyCard: {
    id: null,
    code: null,
    url: null,
    status: null,
    name,
    title: null,
    description: null,
    type: cardType,
    loyaltyCardType: LoyaltyCardTypes.Membershipcard,
    amountOfStamps: null,
    amountOfPoints: null,
    frontImage: imgUrl,
    logo: null,
    locations: null
  },
  loyaltyCardId: null
});

export const emptyLoyaltyProgramFormData = (): LoyaltyProgramFormData => {
  return {
    description: null,
    discount: null,
    loyaltyType: null,
    loyaltyTierType: null,
    loyaltyCardId: null,
    goalBalance: null,
    expirationPeriod: null,
    startingStamps: null,
    maxPerDay: null,
    rewards: null,
    calculationMode: null,
    conditionConfiguration: null,
    count: null,
    amount: null
  };
};

export const programFormDataToMembershipOnboardingRequest = (
  formData: LoyaltyProgramFormData,
  program: SelectedLoyaltyProgramData
): MembershipProgramOnboardingRequest => {
  const { description, discount, loyaltyType, loyaltyTierType, conditionConfiguration, cardImageUrl } = formData;

  return {
    configuration: {
      membership: {
        cardType: program.loyaltyCard.type as LoyaltyMembershipCardType,
        tierType: loyaltyTierType,
        discount,
        cardImageUrl
      }
    },
    conditionConfiguration,
    description,
    loyaltyType
  };
};
