import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { HashMap, TranslocoService } from "@ngneat/transloco";
import { i18nScope } from "../../utils";

@Component({
  selector: "app-form-field-error", // todo: update selector to `lib-form-field-error`
  template: `
    <ng-container *ngIf="formFieldError">
      {{ formFieldError }}
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldErrorComponent {
  @Input() set errors(errors: ValidationErrors | null) {
    this.formFieldError = errors ? this.parseError(errors) : null;
  }

  @Input() label = "";
  @Input() placeholder = "";

  formFieldError: string | null = null;

  @HostBinding("class") hostClass = "invalid-feedback position-absolute";

  constructor(private transloco: TranslocoService) {}

  get fieldName(): string {
    return this.label || this.placeholder;
  }

  private parseError(errors: ValidationErrors): string {
    const key = Object.keys(errors)[0];

    switch (key) {
      // todo custom errors
      case "invalidZip":
      case "invalidDomainPrefix":
      case "invalidDomainName":
      case "onlyNumbersError":
      case "invalidCoupon":
      case "required":
      case "videoUrlPatternError":
        return this.getCommonErrorsTranslation(key);
      case "positiveInteger":
        return this.getCommonFieldErrorsTranslation(key);
      case "max":
        return this.getCommonErrorsTranslation(key, { max: errors[key].max, label: this.fieldName });
      case "min":
        return this.getCommonErrorsTranslation(key, { min: errors[key].min, label: this.fieldName });
      case "minMaxLength":
        return this.getCommonErrorsTranslation("minMaxLength", {
          min: errors[key].requiredLength.min,
          max: errors[key].requiredLength.max,
          label: this.fieldName
        });
      case "maxlength":
        return this.getCommonErrorsTranslation("minMaxLength", { max: errors[key].requiredLength, min: 1, label: this.fieldName });
      case "minlength":
        return this.getCommonErrorsTranslation("minlength", { min: errors[key].requiredLength, label: this.fieldName });
      case "pattern": {
        return this.getPatternErrorTranslation(key, errors[key]);
      }
      case "password": {
        return this.getCommonErrorsTranslation("invalidPassword");
      }
      case "email": {
        return this.transloco.translate(`${i18nScope}.common.error.invalidEmailField`, { field: this.fieldName });
      }
      case "alreadyExistsField": {
        return this.transloco.translate(`${i18nScope}.common.error.alreadyExistsField`, { field: this.fieldName });
      }
      case "isValid": {
        if (errors["type"] === "phone") {
          return this.transloco.translate(`${i18nScope}.common.error.invalidPhone`);
        }
        return key;
      }
      default:
        return key;
    }
  }

  private getCommonErrorsTranslation(errorKey: string, params?: HashMap) {
    return this.transloco.translate(`${i18nScope}.common.error.${errorKey}`, params);
  }

  private getCommonFieldErrorsTranslation(errorKey: string, params?: HashMap) {
    return this.getCommonErrorsTranslation(errorKey, { field: this.fieldName, ...params });
  }

  private getPatternErrorTranslation(key: string, { requiredPattern }: any) {
    if (requiredPattern === `/^[ 0-9a-zA-Z.,!?;:"'&$€£¥+\\-×÷=%]+$/`) {
      return this.transloco.translate(`${i18nScope}.common.error.pattern`);
    }
    return this.getCommonErrorsTranslation(key, { requiredPattern });
  }
}
