import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslocoModule } from "@ngneat/transloco";
import { CfsMenuDto, CfsMenuItemModel } from "clearline-api";
import { i18nScope, provideClearlineCommonTranslocoScope } from "../../utils";

@Component({
  selector: "lib-cfs-menu-items",
  templateUrl: "./cfs-menu-items.component.html",
  styleUrls: ["./cfs-menu-items.component.scss"],
  standalone: true,
  imports: [NgIf, NgFor, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideClearlineCommonTranslocoScope()]
})
export class CfsMenuItemsComponent {
  @Input() menuInfo: CfsMenuDto | null = null;
  @Input() isInteractive = false;

  @Output() menuItemClick = new EventEmitter<CfsMenuItemModel>();
  @Output() cancel = new EventEmitter<void>();

  readonly i18nScope = `${i18nScope}.cfs-menu`;

  onCancel(): void {
    this.cancel.emit();
  }

  onMenuItemClick(menuItem: CfsMenuItemModel): void {
    if (this.isInteractive) {
      this.menuItemClick.emit(menuItem);
    }
  }
}
