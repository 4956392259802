export const stampCardList = [
  { n: 2, name: "2 stamps" },
  { n: 4, name: "4 stamps" },
  { n: 6, name: "6 stamps" },
  { n: 8, name: "8 stamps" },
  { n: 10, name: "10 stamps" },
  { n: 12, name: "12 stamps" }
];

export const pointsCardList = [
  { n: 100, name: "100 points" },
  { n: 200, name: "200 points" },
  { n: 300, name: "300 points" },
  { n: 400, name: "400 points" },
  { n: 500, name: "500 points" }
];

export const rewardsCardList = [
  { n: 200, name: "200 points" },
  { n: 300, name: "300 points" },
  { n: 400, name: "400 points" },
  { n: 500, name: "500 points" },
  { n: 600, name: "600 points" },
  { n: 700, name: "700 points" },
  { n: 800, name: "800 points" },
  { n: 900, name: "900 points" },
  { n: 1000, name: "1000 points" }
];

export const discountCardList = [5, 10, 15, 20, 25, 30, 40, 50].map((n) => ({
  name: `${n}%`,
  n
}));

export const startingStampCardList = [
  { n: 0, name: "0 stamp" },
  { n: 1, name: "1 stamp" }
];

export const startingPointsCardList = [
  { n: 0, name: "0 point" },
  { n: 5, name: "5 points" },
  { n: 10, name: "10 points" }
];

export const startingRewardsCardList = [
  { n: 0, name: "0 point" },
  { n: 5, name: "5 points" },
  { n: 10, name: "10 points" }
];

export const expirationPeriodsList = [
  { n: 6, name: "6 months" },
  { n: 12, name: "12 months" },
  { n: 24, name: "24 months" }
];
