<div class="d-flex flex-column">
  <div *ngIf="isTiredCard" class="d-flex justify-content-end pe-3s mb-5">
    <app-toggle-group-with-label
      [items]="tierButtons"
      [defaultValue]="initialTierButtonValue"
      (change)="onSelectTier($event.value)"
    ></app-toggle-group-with-label>
  </div>

  <div class="d-flex justify-content-center">
    <div class="card-container">
      @for (url of cardUrlList$ | async; track $index) {
        <img
          [alt]="'Select card skin ' + ($index+1)"
          [class.active]="selectedCardIndex === $index"
          [src]="url"
          (click)="onSelectCard(url, $index)"
        >
      }
    </div>
  </div>
</div>
