import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "@app/core/services/data.service";
import {
  IndustryLoyaltyCard,
  LoyaltyCard,
  LoyaltyMembershipCardGetDto,
  LoyaltyMembershipCardImagesRequestDto,
  LoyaltyProgramExpirationDto
} from "@app/models";
import { nonFalsyPayload } from "clearline-api";
import { AppConfigService } from "core-kit";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LoyaltyCardService {
  private serviceUrl = "LoyaltyCard";

  constructor(private http: DataService, private httpClient: HttpClient, private appConfig: AppConfigService) {}

  getAccountLoyaltyCard(accountId?: number): Observable<LoyaltyCard> {
    let url = `${this.serviceUrl}/account`;
    if (accountId) url += `/${accountId}`;
    return this.http.get(url);
  }

  getAccountAvailableLoyaltyCards(accountId: number): Observable<Array<LoyaltyCard>> {
    return this.http.get(`${this.serviceUrl}/account/${accountId}/available`);
  }

  syncLocationLoyaltyCard(locationId: number): Observable<string[]> {
    return this.http.post(`${this.serviceUrl}/syncLocation/${locationId}`);
  }

  getIndustryLoyaltyCards(industryId: number): Observable<Array<IndustryLoyaltyCard>> {
    return this.http.get(`${this.serviceUrl}/industry/${industryId}`);
  }

  getExpirationList(): Observable<Array<LoyaltyProgramExpirationDto>> {
    return this.http.get(`${this.serviceUrl}/expiration/list`);
  }

  getMembershipCardTypes(): Observable<LoyaltyMembershipCardGetDto[]> {
    return this.http.get(`${this.serviceUrl}/membership/cardTypes`);
  }

  getMembershipCardImages(params: LoyaltyMembershipCardImagesRequestDto): Observable<string[]> {
    const { webUrl } = this.appConfig.appData;
    const url = `${webUrl}/loyalty/membership/images`;
    const payload = nonFalsyPayload(params);

    return this.httpClient.get<string[]>(url, { params: this.buildHttpParams(payload) });
  }

  private buildHttpParams(params: any): HttpParams {
    let searchParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        searchParams = searchParams.append(key, params[key]);
      }
    }
    return searchParams;
  }
}
