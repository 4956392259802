<div [formGroup]="form" *transloco="let t; read: 'widgets.enrollLoyalty'">
  <h5>{{ t('labelMinPurchaseRequired') }}</h5>
  <div class="mb-2">{{ t('labelMinPurchaseDescription') }}</div>

  <app-radio-group
    formControlName="minimumRequirementMode"
    itemClassList="form-check mb-3 me-3 col-md-6 col-sm-12"
    groupName="minimumRequirementMode"
    [items]="minReqRadioButtons"
  ></app-radio-group>

  <div class="row mb-3">
    <app-input
      class="col-md-6 col-sm-12"
      type="number"
      [control]="form.controls.minimumAmount"
      [placeholder]="t('labelAmount')"
    ></app-input>

    <app-input
      class="col-md-6 col-sm-12"
      type="number"
      [control]="form.controls.minimumCount"
      [placeholder]="t('labelCount')"
    ></app-input>
  </div>
</div>
