<div [formGroup]="form" class="input-editor">
  <app-input
  class="input-editor-control"
  [control]="currentUrlControl"
  [label]="label"
  [isRequired]="isRequired"
  [classList]="'form-group m-0'">
  </app-input>

  <button
    *ngIf="isEditingControl.value || (!savedUrlControl.value && currentUrlControl.value)"
    class="input-editor-action ms-2"
    [disabled]="!currentUrlControl.valid"
    (click)="onSave()">
    <i class="ri-save-3-fill"></i>
  </button>

  <button
    *ngIf="savedUrlControl.value && !isEditingControl.value"
    class="input-editor-action ms-2"
    (click)="onEdit()">
    <i class="ri-edit-line"></i>
  </button>
</div>
