import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CfsTemplateMediaConfig, defaultCfsTemplateMediaConfig, mediaReadyDelay, TemplatePreviewType, VideoPlayer } from "clearline-common";
import { SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "lib-html-video-player",
  templateUrl: "./html-video-player.component.html",
  styleUrls: ["./html-video-player.component.scss"],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlVideoPlayerComponent implements VideoPlayer, AfterViewInit {
  @Input() mediaConfig: CfsTemplateMediaConfig = { ...defaultCfsTemplateMediaConfig, autoplay: true };
  @Input() innerHTML: SafeHtml = "";
  @Input() previewType: TemplatePreviewType = TemplatePreviewType.Active;
  @Input() displayTime = 0;
  @Input() contentDuration = 0;

  @Output() ready = new EventEmitter();

  @ViewChild("playerWrapper") playerWrapper?: ElementRef<HTMLElement>;

  private mediaCanPlayEventListener: (() => void) | null = null;

  ngAfterViewInit(): void {
    const videoElement: HTMLVideoElement | null = this.getVideoPlayer();

    if (videoElement) {
      const successCallback = () => this.ready.emit();

      this.prepareMediaElementPlayback(successCallback, videoElement, mediaReadyDelay);

      if (this.previewType === TemplatePreviewType.Active) {
        const { muted = true, autoplay } = this.mediaConfig;

        videoElement.muted = muted;
        videoElement.loop = autoplay || !this.contentDuration || !!(this.displayTime && this.displayTime > this.contentDuration);
      }
    }
  }

  play(): void {
    const videoElement: HTMLVideoElement | null = this.getVideoPlayer() as HTMLVideoElement;

    if (videoElement) {
      const successCallback = () => videoElement.play().then();

      this.prepareMediaElementPlayback(successCallback, videoElement, 0);
    }
  }

  pause(): void {}

  stop(): void {
    const videoElement: HTMLVideoElement | null = this.getVideoPlayer() as HTMLVideoElement;

    if (videoElement) {
      this.removeMediaPlayEventListener(videoElement);

      if (!videoElement.paused) {
        videoElement.pause();
      }

      videoElement.currentTime = 0;
    }
  }

  getVideoDuration(): Promise<number> {
    const videoElement: HTMLVideoElement | null = this.getVideoPlayer() as HTMLVideoElement;

    return Promise.resolve(videoElement?.duration || 0);
  }

  getThumbnailUrl(): Promise<string> {
    const videoElement: HTMLVideoElement | null = this.getVideoPlayer() as HTMLVideoElement;

    return Promise.resolve(videoElement?.poster || "");
  }

  hasError(): boolean {
    return false;
  }

  private getVideoPlayer(): HTMLVideoElement | null {
    return this.playerWrapper?.nativeElement.querySelector("video") || null;
  }

  private prepareMediaElementPlayback(callback: () => void, mediaElement: HTMLMediaElement, timeout: number): void {
    if (mediaElement) {
      this.removeMediaPlayEventListener(mediaElement);

      if (this.isMediaElementDataLoaded(mediaElement)) {
        callback();
      } else {
        this.mediaCanPlayEventListener = () => {
          setTimeout(() => callback(), timeout);
          this.removeMediaPlayEventListener(mediaElement);
        };

        mediaElement.addEventListener("canplaythrough", this.mediaCanPlayEventListener.bind(this), { once: true });
      }
    }
  }

  private removeMediaPlayEventListener(mediaElement: HTMLMediaElement): void {
    if (this.mediaCanPlayEventListener) {
      mediaElement.removeEventListener("canplaythrough", this.mediaCanPlayEventListener);
      this.mediaCanPlayEventListener = null;
    }
  }

  private isMediaElementDataLoaded(mediaElement: HTMLMediaElement): boolean {
    const successState = 4;

    return mediaElement.readyState >= successState;
  }
}
