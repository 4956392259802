import { Injectable } from "@angular/core";
import { EnumHelpers } from "@app/core/helpers";
import { AccountService } from "@app/core/services/account.service";
import {
  Account,
  AccountConfigurationDto,
  AccountPosSystemSettingsConfiguration,
  LoyaltyProgramSettingsGetDto,
  SubscriptionPlan,
  SubscriptionPlanOptions
} from "@app/models";
import { AccountConfigurationContentProviderDto } from "@app/models/content-providers";
import { AccountsService, LoyaltyProgramService, PosSystemService, SubscriptionPlanService } from "@app/services";
import { ContentProviderType } from "cfs-communication-pack";
import { ProfileModel, RoleTypes } from "core-kit";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { Permission, permissionByRoute } from "./permissions";
import { AccountConfigurationLoyaltyDto } from "clearline-api";

type MenuItemPermission = Record<Permission, boolean>;

interface MenuItemAvailabilityParams {
  plan: SubscriptionPlan;
  account: Account;
  posSystemConfiguration: AccountPosSystemSettingsConfiguration;
  loyaltyProgramSettings: LoyaltyProgramSettingsGetDto;
}

const defaultAccessControlConfig: MenuItemPermission = generateAccessControlConfig(true);
const disabledAccessControlConfig: MenuItemPermission = generateAccessControlConfig(false);

function generateAccessControlConfig(value: boolean): MenuItemPermission {
  return EnumHelpers.getKeys(Permission).reduce((acc, key) => ({ ...acc, [key]: value }), {} as MenuItemPermission);
}

@Injectable()
export class UserPermissionService {
  private profileSubscriptionId = "";
  private profileLogoutTooltip = "";
  private activationTransactionsReportName = "";
  private userPermissionsSrc$ = new BehaviorSubject<MenuItemPermission>(null);
  private subscriptionPlanOptionsSrc$ = new BehaviorSubject<SubscriptionPlanOptions>(null);
  private isItemlessPlan = false;

  get customTransactionsReportName(): string {
    return this.activationTransactionsReportName;
  }

  get logoutTooltip(): string {
    return this.profileLogoutTooltip;
  }

  get subscriptionId(): string {
    return this.profileSubscriptionId;
  }

  get isItemlessBulkPlan(): boolean {
    // temporary field; todo: remove after the backend implementation
    return this.isItemlessPlan;
  }

  readonly userPermission$: Observable<MenuItemPermission> = this.userPermissionsSrc$.pipe(filter((config) => !!config));
  readonly subscriptionPlanOptions$: Observable<SubscriptionPlanOptions> = this.subscriptionPlanOptionsSrc$.asObservable();
  readonly ready$: Observable<boolean> = this.userPermissionsSrc$.pipe(
    switchMap((config) => (config ? of(true) : this.initialize().pipe(map((v) => !!v))))
  );

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private accountsService: AccountsService,
    private loyaltyProgramService: LoyaltyProgramService,
    private subscriptionPlanService: SubscriptionPlanService,
    private posSystemService: PosSystemService
  ) {}

  hasPermission(route: string): boolean {
    if (this.isPermissionCheckSkipped() || route === "/access-denied") {
      return true;
    }

    const routeKey = this.parseRoute(route);
    const permissionKey = permissionByRoute[routeKey];
    const config = this.userPermissionsSrc$.value;
    const hasPermission = permissionKey && config && config[permissionKey];

    return hasPermission;
  }

  hasActionPermission(permission: Permission): boolean {
    if (this.isPermissionCheckSkipped()) {
      return true;
    }

    const config = this.userPermissionsSrc$.value;
    const hasPermission = config && config[permission];

    return hasPermission;
  }

  private parseRoute(route: string): string {
    const path = route.split("?")[0].split("#")[0];
    // removes the entire segment starting from the first digit part to the end of the URL.
    // '/aaa/bbb/111/ccc/2222' -> '/aaa/bbb'
    const resultPath = path.replace(/\/\d+.*$/, "");

    return resultPath;
  }

  private isPermissionCheckSkipped(): boolean {
    return this.accountService.isUserRequireMinRole(RoleTypes.PartnerAdmin) || !this.accountService.user?.doneQuickSetup;
  }

  private initialize(): Observable<MenuItemPermission> {
    if (!this.authService.isLoggedIn || this.isPermissionCheckSkipped()) {
      return of(defaultAccessControlConfig);
    }

    const activeUser$ = this.accountService.userChanged$.pipe(filter((v) => !!v));

    return activeUser$.pipe(
      tap((profile) => {
        const { name, family_name, subscriptionId } = profile;
        this.profileLogoutTooltip = `${name} ${family_name}`;
        this.profileSubscriptionId = subscriptionId;
      }),
      switchMap((profile) => this.getLocationPlan(profile)),
      switchMap((plan) => {
        if (!!plan && !!plan.options) {
          return this.parsePermissionsByPlan(plan);
        }

        return of(disabledAccessControlConfig).pipe(tap((config) => this.userPermissionsSrc$.next(config)));
      })
    );
  }

  private parsePermissionsByPlan(plan: SubscriptionPlan): Observable<MenuItemPermission> {
    return of(plan).pipe(
      tap((_plan) => {
        this.isItemlessPlan = this.getIsItemlessBulkPlan(_plan);

        this.subscriptionPlanOptionsSrc$.next(_plan.options);
      }),
      switchMap((_plan) => this.getMenuItemAvailabilityParams(_plan)),
      map((params: MenuItemAvailabilityParams) => this.buildMenuItemPermissions(params)),
      tap((config) => this.userPermissionsSrc$.next(config)),
      catchError((error) => {
        console.error("Error in userPermissionService.initialize():", error);
        return of(defaultAccessControlConfig);
      })
    );
  }

  private getMenuItemAvailabilityParams(plan: SubscriptionPlan): Observable<MenuItemAvailabilityParams> {
    const isManager: boolean = this.accountService.isUserInRole(RoleTypes.Manager);
    const isLocationManager: boolean = this.accountService.isUserInRole(RoleTypes.LocationManager);
    const additionalRequestList = this.getAdditionalRequests(isManager, isLocationManager);

    return combineLatest([of(plan), ...additionalRequestList]).pipe(
      map(([plan, account, posSystemConfiguration, loyaltyProgramSettings]) => ({
        plan,
        account,
        posSystemConfiguration,
        loyaltyProgramSettings
      }))
    );
  }

  private getAdditionalRequests(isManager: boolean, isLocationManager: boolean): Observable<any>[] {
    return isManager || isLocationManager
      ? [
          this.accountsService.getCurrentAccount().pipe(tap((account: Account) => this.accountService.setCurrentAccount(account))), // fixme duplicate
          this.posSystemService.getAccountPosSystemSettings().pipe(
            catchError(() => of(null)),
            map((settings) => settings?.configuration || null)
          ),
          this.loyaltyProgramService.getSettings().pipe(catchError(() => of(null)))
        ]
      : [];
  }

  private buildMenuItemPermissions(params: MenuItemAvailabilityParams): MenuItemPermission {
    const { plan, account, posSystemConfiguration, loyaltyProgramSettings } = params;
    const o: SubscriptionPlanOptions = plan.options;

    const configuration: AccountConfigurationDto = account.configuration;
    const isManagerSettingsAvailable: boolean = this.getIsManagerSettingsAvailable(configuration);
    const { reportName, isReportEnabled } = configuration.reporting?.activationTransactions || {};
    const isDigitalScreensEnabled: boolean = this.getIsDigitalScreensEnabled(configuration);
    const isPosIntegrationAvailable: boolean = o.hasPosIntegration && account?.isPosIntegrationAvailable === true;
    const isLoyaltyEnabled = loyaltyProgramSettings?.state === "Active";

    const config: MenuItemPermission = {
      ...defaultAccessControlConfig,
      [Permission.AdvancedTools]: o.hasAdvancedTools,
      [Permission.AppsShortURLs]: o.hasAppsShortUrlsAndQrs,
      [Permission.BulkLinks]: o.hasBulkLinksAndQrsList,
      [Permission.CFSTerminals]: o.hasCfs,
      [Permission.CompanyConnectors]: o.hasConnectors,
      [Permission.Contacts]: o.hasExternalContacts,
      [Permission.CustomShortURLs]: o.hasCustomShortUrlsAndQrs,
      [Permission.Dashboard]: o.hasDashboard,
      [Permission.Distributions]: o.hasDistribution,
      [Permission.Customers]: o.hasCustomers,
      [Permission.ThirdPartyAnalytics]: o.hasThirdPartyAnalytics,
      [Permission.MarketingCampaigns]: o.hasMarketingCampaigns,
      [Permission.MarketingCenter]: o.hasCmc,
      [Permission.Devices]: o.hasDevices,
      [Permission.OutboundMarketing]: o.hasOutboundMarketing,
      [Permission.PrintReceipts]: o.hasPrintReceipt,
      [Permission.PixelTags]: o.hasTrackingPixel,
      [Permission.Products]: o.hasProducts,
      [Permission.ReportingAnalytics]: o.hasReportingAndAnalytics,
      [Permission.Templates]: o.hasCfs,
      [Permission.URLsAndQRCodes]: o.hasUrlsAndQrCodes,

      // Actions permission:
      [Permission.CanCreateBulks]: o.canCreateBulks,
      [Permission.CanDeleteBulks]: o.canDeleteBulks,
      [Permission.CanViewBulks]: o.canViewBulks,

      // Dynamically enabled:
      // POS:
      [Permission.POSIntegration]: o.hasPosIntegration, // globally enabled
      [Permission.POSActivityReport]: isPosIntegrationAvailable,
      [Permission.POSReceiptSettings]: this.getIsReceiptPromotionEnabled(o.hasPosIntegration, posSystemConfiguration),
      [Permission.POSRules]: isPosIntegrationAvailable,
      [Permission.POSSettings]: isPosIntegrationAvailable,

      // Digital Screens:
      [Permission.DigitalScreens]: isDigitalScreensEnabled,
      [Permission.DigitalScreensDashboard]: isDigitalScreensEnabled,

      // Loyalty:
      [Permission.LoyaltyProgram]: o.hasLoyaltyProgram && isLoyaltyEnabled,
      [Permission.LoyaltyReporting]: o.hasLoyaltyProgramReporting && isLoyaltyEnabled,

      // Reports:
      [Permission.CustomTransactionReport]: isReportEnabled,

      // Company settings:
      [Permission.ManagerSettings]: isManagerSettingsAvailable
    };

    this.activationTransactionsReportName = reportName || "";

    return config;
  }

  private getLocationPlan(profile?: ProfileModel): Observable<SubscriptionPlan> {
    const isLocationManager: boolean = this.accountService.isUserInRole(RoleTypes.LocationManager);
    const locationId: number = +this.accountService.user.locationId;
    const hasLocationPlan: boolean = Number.isInteger(locationId) && (!!profile?.doneQuickSetup || isLocationManager);

    return hasLocationPlan ? this.subscriptionPlanService.getLocationPlan(locationId) : of(null);
  }

  private getIsDigitalScreensEnabled(accountConfiguration: AccountConfigurationDto): boolean {
    return !!accountConfiguration.externalConnection?.contentProviders?.find(
      (item: AccountConfigurationContentProviderDto) => item.providerType === ContentProviderType.Yodeck && item.isEnabled
    );
  }

  private getIsManagerSettingsAvailable(configuration: AccountConfigurationDto): boolean {
    const loyalty: AccountConfigurationLoyaltyDto = configuration.loyalty;

    return !!loyalty?.ambassadorFeatureAvailable;
  }

  private getIsReceiptPromotionEnabled(hasPosIntegration: boolean, posSystemSettings: AccountPosSystemSettingsConfiguration): boolean {
    return hasPosIntegration && !!posSystemSettings?.isReceiptPromotionEnabled;
  }

  private getIsItemlessBulkPlan(plan: SubscriptionPlan): boolean {
    const itemlessBulkPlanProductIds = ["itemless_plan", "itemless_plan_10", "fanda_itemless_plan", "fanda_itemless_plan_10"];

    return itemlessBulkPlanProductIds.includes(plan.productId);
  }

  updatePermissionsByPosSettingsUpdates(posSettingsConfiguration: AccountPosSystemSettingsConfiguration): void {
    const planOptions = this.subscriptionPlanOptionsSrc$.value;

    if (planOptions) {
      this.userPermissionsSrc$.next({
        ...this.userPermissionsSrc$.value,
        [Permission.POSReceiptSettings]: this.getIsReceiptPromotionEnabled(planOptions.hasPosIntegration, posSettingsConfiguration)
      });
    }
  }
}
