import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

interface GlobalYT {
  Player: YT.Player;
  loaded: number;
  ready: (callback: () => void) => void;
}

declare global {
  interface Window {
    YT: GlobalYT;
    onYouTubeIframeAPIReady: (() => void) | undefined;
  }
}

@Injectable({
  providedIn: "root"
})
export class YoutubePlayerService {
  private apiReadySubject = new ReplaySubject<void>(1);

  onApiReady$: Observable<void> = this.apiReadySubject.asObservable();

  constructor() {
    if (window.YT && window.YT.Player) {
      this.apiReadySubject.next();
    } else {
      window.onYouTubeIframeAPIReady = () => {
        this.apiReadySubject.next();
      };

      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
    }
  }
}
