<ng-container [ngSwitch]="type">
  <lib-html-video-player
    *ngSwitchCase="VideoTemplateType.Import"
    [mediaConfig]="mediaConfig"
    [innerHTML]="innerHTML"
    [previewType]="previewType"
    [displayTime]="displayTime"
    [contentDuration]="contentDuration"
    (ready)="onPlayerReady()">
  </lib-html-video-player>

  <lib-vimeo-player
    *ngSwitchCase="VideoTemplateType.Vimeo"
    class="video-player"
    [mediaConfig]="mediaConfig"
    [index]="index"
    [url]="url"
    (ready)="onPlayerReady()">
  </lib-vimeo-player>

  <lib-youtube-player
    *ngSwitchCase="VideoTemplateType.YouTube"
    class="video-player"
    [mediaConfig]="mediaConfig"
    [index]="index"
    [url]="url"
    (ready)="onPlayerReady()">
  </lib-youtube-player>

</ng-container>
