<ng-container *ngIf="forPrint; else fullTemplate">
  <app-printable-template
    [template]="template"
    (print)="onPrint()"
    class="template">
  </app-printable-template>
</ng-container>

<ng-template #fullTemplate>
  <lib-cfs-template
    *ngIf="template?.generatedBody"
    [mediaConfig]="{ muted: true, autoplay: true, loop: true }"
    [previewType]="TemplatePreviewType.Active"
    [template]="template"
    [forPrint]="false"
    class="template">
  </lib-cfs-template>
</ng-template>
