import { Injectable } from "@angular/core";
import { LoyaltyMembershipCardImagesRequestDto } from "@app/models";
import { LoyaltyCardService } from "@app/services";
import { LoyaltyMembershipTierType } from "clearline-api";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class MembershipCardSettingsService {
  private cacheUrls = new Map<LoyaltyMembershipTierType, string[]>();

  constructor(private loyaltyCardService: LoyaltyCardService) {}

  getCardUrlList(params: LoyaltyMembershipCardImagesRequestDto): Observable<string[]> {
    const cachedUrls = this.cacheUrls.get(params.tierType as LoyaltyMembershipTierType);

    return cachedUrls ? of(cachedUrls) : this.fetchCardUrls(params);
  }

  private fetchCardUrls(params: LoyaltyMembershipCardImagesRequestDto): Observable<string[]> {
    return this.loyaltyCardService.getMembershipCardImages(params).pipe(
      tap((urls) => {
        this.cacheUrls.set(params.tierType as LoyaltyMembershipTierType, urls);
      })
    );
  }
}
