import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LoyaltyOnboardingRequest } from "../enroll-loyalty-widget-settings";

@Component({
  selector: "app-create-loyalty-program-modal",
  templateUrl: "./create-loyalty-program-modal.component.html",
  styleUrls: ["./create-loyalty-program-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateLoyaltyProgramModalComponent {
  title: string; // modal window parameter
  industryId: number; // modal window parameter
  locationId: number; // modal window parameter
  enrollLoyaltyLink: string; // modal window parameter

  constructor(public activeModal: NgbActiveModal) {}

  dismiss(): void {
    this.activeModal.dismiss();
  }

  saveResult(loyaltyProgramDetailsResult: LoyaltyOnboardingRequest): void {
    this.activeModal.close(loyaltyProgramDetailsResult);
  }
}
