<div *ngIf="onboardingRequest as req">
  <div class="row" *transloco="let t; read: 'widgets.enrollLoyalty'">
    <div *ngIf="req.configuration.membership as membership" class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
      <app-lp-details-card [title]="t('titleProgram')">
        <div class="col-12 font-size-13">
          <div class="row">
            <div class="col-xxl-3 col-xl-4 col-lg-3 col-md-12">
              <img [src]="membership.cardImageUrl" class="card-image" />
            </div>
            <div class="col-xxl-9 col-xl-8 col-lg-9 col-md-12">
              <div class="col-12 d-flex justify-content-between mb-2">
                <div class="text-muted">{{ t("labelProgramTitle") }}</div>
                <div class="text-end">{{ t('membershipTitle') }}</div>
              </div>

              <div class="col-12 d-flex justify-content-between mb-2">
                <div class="text-muted">{{ t("labelProgramType") }}</div>
                <div class="text-end">{{ ('enums.LoyaltyMembershipCardType.' + membership.cardType) | transloco }}</div>
              </div>

              <div *ngIf="membership.cardType === LoyaltyMembershipCardType.Tiered" class="col-12 d-flex justify-content-between mb-2">
                <div class="text-muted">{{ t("labelProgramTier") }}</div>
                <div class="text-end">{{ ('enums.LoyaltyMembershipTierType.' + membership.tierType) | transloco }}</div>
              </div>

              <div *ngIf="membership.cardType === LoyaltyMembershipCardType.Discount" class="col-12 d-flex justify-content-between mb-2">
                <div class="text-muted">{{ 'enums.LoyaltyMembershipCardType.Discount' | transloco  }}</div>
                <div class="text-end">{{ membership.discount }}%</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div *ngIf="req.description as description" class="col-12 d-flex justify-content-between mb-2">
              <div class="text-muted">{{ t("labelProgramDescription") }}</div>
              <span class="strong-normal text-end" [innerHTML]="description"></span>
            </div>
          </div>
        </div>
      </app-lp-details-card>


      <ng-container *ngIf="req.conditionConfiguration as cc">
        <app-lp-details-card *ngIf="!!cc.minimumAmount || !!cc.minimumCount" [title]="t('titleSettings')">
          <div class="col-12 d-flex justify-content-between mb-2 font-size-14">
            <ng-container *ngIf="!!cc.minimumAmount">
              <div class="text-muted">{{ t("labelPurchaseMinAmount") }}</div>
              <div>{{ t('labelAmountValue', { value: cc.minimumAmount}) }}</div>
            </ng-container>
            <ng-container *ngIf="!!cc.minimumCount">
              <div class="text-muted">{{ t("minCount") }}</div>
              <div>{{ t('labelCountValue', { value: cc.minimumCount}) }}</div>
            </ng-container>
          </div>
        </app-lp-details-card>
      </ng-container>
    </div>
  </div>
</div>
