<div *ngIf="loyaltyProgramDetailsResult as res">
  <div class="row" *transloco="let t; read: 'widgets.enrollLoyalty'">
    <!--BEGIN LEFT COLUMN -->
    <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
      <!-- PROGRAM -->
      <app-lp-details-card *ngIf="selectedCard && selectedCardTypeSetting" [title]="t('titleProgram')">
        <div class="col-12 font-size-13">
          <div class="row">
            <div class="col-xxl-3 col-xl-4 col-lg-3 col-md-12">
              <img [ngStyle]="{ 'background-image': 'url(' + selectedCard.frontImage + ')' }" class="card-image" />
            </div>
            <div class="col-xxl-9 col-xl-8 col-lg-9 col-md-12">
              <div class="col-12 d-flex justify-content-between mb-2">
                <div class="text-muted">{{ t("labelProgramTitle") }}</div>
                <div class="text-end">{{ selectedCard.title }}</div>
              </div>

              <div class="col-12 d-flex justify-content-between mb-2">
                <div class="text-muted">{{ t("labelProgramType") }}</div>
                <div class="text-end">{{ selectedCardTypeSetting.name | transloco }}</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 d-flex justify-content-between mb-2">
              <div class="text-muted">{{ t("labelProgramLoyaltyCard") }}</div>
              <div class="text-end">{{ selectedCard.name }}</div>
            </div>

            <div *ngIf="res.description as description" class="col-12 d-flex justify-content-between mb-2">
              <div class="text-muted">{{ t("labelProgramDescription") }}</div>
              <span class="strong-normal text-end" [innerHTML]="description"></span>
            </div>
          </div>
        </div>
      </app-lp-details-card>


      <!-- SETTINGS -->
      <app-lp-details-card *ngIf="selectedCardTypeSetting" [title]="t('titleSettings')">
        <!-- Goal Balance -->
        <div class="col-12 d-flex justify-content-between mb-2 font-size-14">
          <div class="text-muted">{{ t("labelGoalBalance") }}</div>
          <div>{{ res.goalBalance }} {{ selectedCardTypeSetting.suffixList }}</div>
        </div>

        <!-- Starting position -->
        <div class="col-12 d-flex justify-content-between mb-2 font-size-14">
          <div class="text-muted">{{ t("labelStartingPosition") }}</div>
          <div>{{ res.startingStamps }} {{ selectedCardTypeSetting.suffixList }}</div>
        </div>

        <!-- Max per day -->
        <div class="col-12 d-flex justify-content-between mb-2 font-size-14">
          <div class="text-muted">{{ t("labelMaxPerDay") }}</div>
          <div>{{ res.maxPerDay }} {{ selectedCardTypeSetting.suffixList }}</div>
        </div>

        <!-- Minimum requirements -->
        <div *ngIf="res.calculationConfiguration as cc" class="col-12 d-flex justify-content-between mb-2 font-size-14">
          <ng-container *ngIf="res.conditionConfiguration as condConf">
            <ng-container *ngIf="!!condConf.minimumAmount">
              <div class="text-muted">{{ t("labelPurchaseMinAmount") }}</div>
              <div>{{ t('labelAmountValue', { value: condConf.minimumAmount}) }}</div>
            </ng-container>
            <ng-container *ngIf="!!condConf.minimumCount">
              <div class="text-muted">{{ t("minCount") }}</div>
              <div>{{ t('labelCountValue', { value: condConf.minimumCount}) }}</div>
            </ng-container>
          </ng-container>
        </div>

        <!-- Expiration period -->
        <div class="col-12 d-flex justify-content-between mb-2 font-size-14" *ngIf="res.expiration as exp">
          <div class="text-muted">{{ expirationPeriodTitle }}</div>
          <div class="font-size-13">{{ expirationPeriod }}</div>
        </div>
      </app-lp-details-card>
    </div>
    <!--END LEFT COLUMN -->


    <!--BEGIN RIGHT COLUMN -->
    <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
      <!-- REWARDS -->
      <app-lp-details-card [title]="t('titleRewards')">
        <div class="">
          <div class="col-12 d-flex justify-content-between mb-2 font-size-14" *ngFor="let item of rewardsList; index as i">
            <div class="text-muted">{{ item.name }}</div>
            <div class="font-size-13">{{ item.couponName }}</div>
          </div>
        </div>
      </app-lp-details-card>


      <!-- POINTS VALUE -->
      <app-lp-details-card
        *ngIf="!isStampCard"
        [title]="t('titlePoints')"
      >
        <!-- Calculation configuration -->
        <ng-container *ngIf="res.calculationConfiguration as cc">
          <div *ngIf="!isStampCard" class="col-12 d-flex justify-content-between mb-2 font-size-14">
            <div class="text-muted">{{ t("labelPointsToDollars") }}</div>
            <div >{{ t('labelAmountValue', { value: cc.amount }) }} = {{ t('labelPointValue', { value: cc.count }) }}</div>
          </div>
        </ng-container>
      </app-lp-details-card>
    </div>
    <!--END RIGHT COLUMN -->

    <div *ngIf="enrollLoyaltyLink" class="col-12">
      <div class="card bg-light mb-3 px-3">
        <div class="row card-body">
          <h5 class="p-0 mb-2">{{ t("labelProgramLink") }}</h5>
          <div class="col-12 h-40 bg-white d-flex justify-content-between mb-2 font-size-14">
            <div id="program-link-copy" class="align-self-center text-muted">{{ enrollLoyaltyLink }}</div>
            <i
              class="ri-file-copy-line font-size-20 align-self-center btn-copy"
              [ngbTooltip]="t('copyProgramLink')"
              (click)="copyUrl()"
              *ngIf="enrollLoyaltyLink"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
