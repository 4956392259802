/// <reference types="youtube" preserve="true" />
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, NgZone, OnDestroy, ViewChild } from "@angular/core";
import { ExternalLinkVideoPlayerComponent } from "../external-link-video-player";
import {
  AutoHide,
  AutoPlay,
  Controls,
  FullscreenButton,
  IvLoadPolicy,
  KeyboardControls,
  Loop,
  Mute,
  RelatedVideos
} from "./youtube-player";
import { mediaReadyDelay } from "../../cfs-template";
import { YoutubePlayerService } from "./youtube-player.service";
import { take } from "rxjs/operators";
import { TranslocoModule } from "@ngneat/transloco";
import { RelativeLoaderComponent } from "../../relative-loader";
import { provideClearlineCommonTranslocoScope } from "../../../utils";

export const youtubeUrlPattern =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[?&]\S*)?$/;

@Component({
  selector: "lib-youtube-player",
  templateUrl: "./youtube-player.component.html",
  styleUrls: ["./youtube-player.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslocoModule, RelativeLoaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideClearlineCommonTranslocoScope()]
})
export class YoutubePlayerComponent extends ExternalLinkVideoPlayerComponent implements OnDestroy {
  @ViewChild("player", { static: true }) playerElement: ElementRef | undefined;

  private player: YT.Player | null = null;

  protected videoId = "";
  protected urlPattern = youtubeUrlPattern;

  constructor(private service: YoutubePlayerService, private zone: NgZone) {
    super();
  }

  play(): void {
    this.player?.playVideo();
  }

  pause(): void {
    this.player?.pauseVideo();
  }

  stop(): void {
    this.player?.stopVideo();
  }

  getVideoDuration(): Promise<number> {
    return Promise.resolve(this.player?.getDuration() || 0);
  }

  getThumbnailUrl(): Promise<string> {
    const thumbnailUrl = this.videoId && this.player ? `https://img.youtube.com/vi/${this.videoId}/maxresdefault.jpg` : "";

    return Promise.resolve(thumbnailUrl);
  }

  ngOnDestroy() {
    void this.resetPlayer();
  }

  protected loadPlayer(): void {
    if (this.videoId) {
      this.service.onApiReady$.pipe(take(1)).subscribe(() => this.createPlayer());
    } else {
      void this.resetPlayer();
    }
  }

  protected getVideoIdByUrl(url: string): string {
    const match = url.match(this.urlPattern) || [];

    return match.length ? match[1] : "";
  }

  private createPlayer(): void {
    const { muted = true, loop = false } = this.mediaConfig || {};

    this.player?.destroy();

    this.zone.runOutsideAngular(() => {
      this.player = new YT.Player(this.playerElement?.nativeElement, {
        videoId: this.videoId,
        playerVars: {
          autohide: AutoHide.HideAllControls,
          autoplay: AutoPlay.NoAutoPlay,
          controls: Controls.Hide,
          disablekb: KeyboardControls.Disable,
          fs: FullscreenButton.Hide,
          iv_load_policy: IvLoadPolicy.Hide,
          loop: loop ? Loop.Loop : Loop.SinglePlay,
          mute: muted ? Mute.Muted : Mute.NotMuted,
          rel: RelatedVideos.Hide,
          playlist: this.videoId
        },
        events: {
          onError: this.setError.bind(this),
          onReady: this.onPlayerReady.bind(this),
          onStateChange: this.onPlayerStateChange
        }
      });
    });
  }

  private async resetPlayer(): Promise<void> {
    console.log("Vimeo player reset", !!this.player); // todo: remove logs after testing
    if (this.player) {
      await this.player.destroy();
      this.player = null;
    }

    return Promise.resolve();
  }

  private onPlayerReady(event: YT.PlayerEvent): void {
    const iframeElement: HTMLIFrameElement | null = this.player?.getIframe() || null;

    if (iframeElement) {
      iframeElement.style.pointerEvents = "none";
    }

    setTimeout(() => {
      console.log("Youtube video loaded in settimeout: ", event); // todo: remove logs after testing
      this.loaded = true;
      this.ready.emit();

      if (!!this.mediaConfig.autoplay) {
        this.play();
      }
    }, mediaReadyDelay);
  }

  private onPlayerStateChange(event: YT.OnStateChangeEvent): void {
    const playerState: YT.PlayerState = event.data;
    const playerStateKey = Object.keys(YT.PlayerState).find((key) => YT.PlayerState[key as keyof typeof YT.PlayerState] === playerState);
    console.log("onPlayerStateChange", playerStateKey); // todo: remove later
  }
}
