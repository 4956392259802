import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import {
  CfsTemplateMediaConfig,
  TemplatePreviewType,
  TemplateProperties,
  UrlContentSettings,
  VideoTemplateContentType
} from "../../../models";
import { YoutubePlayerComponent, VimeoPlayerComponent } from "../../external-link-video-player";
import { SafeHtml } from "@angular/platform-browser";
import { HtmlVideoPlayerComponent } from "./html-video-player";
import { defaultCfsTemplateMediaConfig } from "../cfs-template.config";

export type VideoPlayerComponent = HtmlVideoPlayerComponent | YoutubePlayerComponent | VimeoPlayerComponent;

@Component({
  selector: "lib-video-template",
  templateUrl: "./video-template.component.html",
  styleUrls: ["./video-template.component.scss"],
  standalone: true,
  imports: [CommonModule, HtmlVideoPlayerComponent, YoutubePlayerComponent, VimeoPlayerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoTemplateComponent {
  @Input() mediaConfig: CfsTemplateMediaConfig = { ...defaultCfsTemplateMediaConfig, autoplay: true };
  @Input() innerHTML: SafeHtml = "";
  @Input() index = 0;
  @Input() previewType: TemplatePreviewType = TemplatePreviewType.Active;
  @Input() displayTime = 0;

  @Input() set templateProperties(value: TemplateProperties | undefined) {
    if (value) {
      const { VideoUrl, VideoContentType, ContentDuration } = value as UrlContentSettings;
      this.url = VideoUrl ?? "";
      this.type = VideoContentType ?? VideoTemplateContentType.Import;
      this.contentDuration = Number(ContentDuration) ?? 0;
    }
  }

  @Output() ready = new EventEmitter();

  @ViewChild(YoutubePlayerComponent) youtubePlayer?: YoutubePlayerComponent;
  @ViewChild(VimeoPlayerComponent) vimeoPlayer?: VimeoPlayerComponent;
  @ViewChild(HtmlVideoPlayerComponent) htmlVideoPlayer?: HtmlVideoPlayerComponent;

  readonly VideoTemplateType = VideoTemplateContentType;

  type: VideoTemplateContentType = VideoTemplateContentType.Import;
  url = "";
  player: VideoPlayerComponent | null = null;
  contentDuration = 0;

  onPlayerReady(): void {
    this.ready.emit();

    this.player = this.getVideoPlayer();

    if (this.mediaConfig.autoplay) {
      this.play();
    }
  }

  play(): void {
    if (!this.player) {
      this.player = this.getVideoPlayer();
    }

    this.player?.play();
  }

  stop(): void {
    if (!this.player) {
      this.player = this.getVideoPlayer();
    }

    this.player?.stop();
  }

  private getVideoPlayer(): VideoPlayerComponent | null {
    switch (this.type) {
      case VideoTemplateContentType.YouTube:
        return this.youtubePlayer || null;
      case VideoTemplateContentType.Vimeo:
        return this.vimeoPlayer || null;
      case VideoTemplateContentType.Import:
        return this.htmlVideoPlayer || null;
      default:
        return null;
    }
  }
}
